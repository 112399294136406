/* eslint-disable */
// prettier-ignore
import { Button, TextInput } from '@tremor/react';
import React from 'react';
import { useState } from 'react';
import { toast } from "sonner"


import {
    CloseButton,
    Popover, PopoverPanel,
    Field, PopoverButton, Radio, RadioGroup
} from '@headlessui/react'

import cysrConfig from "layouts/cysr/config";
import { UploadSharp } from '@mui/icons-material';
// Tremor Raw input component, learn more: https://raw.tremor.so/docs/inputs/input

export const focusInput = [
    // base
    'focus:ring-2',
    // ring color
    'focus:ring-blue-200 focus:dark:ring-blue-700/30',
    // border color
    'focus:border-blue-400 focus:dark:border-blue-700',
];

export const hasErrorInput = [
    // base
    'ring-2',
    // border color
    'border-red-500 dark:border-red-700',
    // ring color
    'ring-red-200 dark:ring-red-700/30',
];

const inputStyles = ({
    base: [
        // base
        'relative cursor-pointer block w-full appearance-none rounded-tremor-default border px-2.5 py-1.5 shadow-tremor-input dark:shadow-dark-tremor-input outline-none !text-tremor-default',
        // border color
        'border-tremor-border dark:border-dark-tremor-border',
        // text color
        'text-tremor-content-strong dark:text-dark-tremor-content-strong',
        // placeholder color
        'placeholder-tremor-content-subtle dark:placeholder-dark-tremor-content-subtle',
        // background color
        'bg-tremor-background dark:bg-dark-tremor-background',
        // disabled
        'disabled:bg-tremor-background-subtle disabled:text-tremor-content-subtle',
        'disabled:dark:bg-dark-tremor-background-subtle disabled:dark:text-dark-tremor-content-subtle',
        // file
        [
            'file:text-tremor-default file:-my-1.5 file:-ml-2.5 file:h-[36px] file:cursor-pointer file:rounded-l-tremor-small file:border-0 file:px-3 file:py-1.5 file:outline-none file:rounded-r-none',
            'file:border-solid file:border-tremor-border file:bg-tremor-background-muted file:text-tremor-content file:dark:bg-dark-tremor-background-muted file:dark:border-dark-tremor-border hover:file:bg-tremor-background-subtle/80 file:dark:bg-dark-tremor-background-muted hover:file:dark:bg-dark-tremor-background-subtle/30',
            'file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem]',
            'disabled:pointer-events-none file:disabled:pointer-events-none file:disabled:bg-tremor-background-subtle file:disabled:text-tremor-content file:disabled:dark:bg-dark-tremor-background-subtle file:disabled:dark:border-gray-700 focus:outline-none',
        ],
        // focus
        focusInput,
        // invalid (optional)
        // "aria-[invalid=true]:dark:ring-red-400/20 aria-[invalid=true]:ring-2 aria-[invalid=true]:ring-red-200 aria-[invalid=true]:border-red-500 invalid:ring-2 invalid:ring-red-200 invalid:border-red-500"
        // remove search cancel button (optional)
        '[&::--webkit-search-cancel-button]:hidden [&::-webkit-search-cancel-button]:hidden [&::-webkit-search-decoration]:hidden',
    ],
    hasError: {
        true: hasErrorInput,
    },
    // number input
    enableStepper: {
        true: '[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
    },
});

const Input = React.forwardRef(
    ({ className, hasError, enableStepper, type, ...props }, forwardedRef) => {
        return (
            <div className="relative">
                <TextInput
                    ref={forwardedRef}
                    type={type}
                    className={"max-w-xs mt-2"+inputStyles.base}
                    style={{
                        fontSize: 14,
                        maxHeight: 38
                    }}
                    {...props}
                />
            </div>
        );
    },
);

Input.displayName = 'Input';

export function CtrlUploadControlEvidence({ controlID,loadControl, data, setCompanyEvidences, artifactsData }) {
const evidenceID = data.evidencerequest_id;
    const [formData, setFormData] = useState({
        artifact_name: artifactsData && artifactsData.artifact_name ? artifactsData.artifact_name : '',
        artifact_description: artifactsData && artifactsData.artifact_description ? artifactsData.artifact_description : '',
        artifact_expiration_date: artifactsData && artifactsData.artifact_expiration_date ? artifactsData.artifact_expiration_date : '',
        artifact_file: null,
        artifact_owner: artifactsData && artifactsData.artifact_owner ? artifactsData.artifact_owner : '',
        artifact_control: [controlID],
        artifact_evidencerequest: [evidenceID],
    });
    const handleChange = (event) => {
        const { name, value, files } = event.target;
        if(files[0].size > 8097152) {
            toast.error("File is way too big!");
            event.target.value = ''
            return
         }
        if (name === 'artifact_file') {
            setFormData({
                ...formData,
                [name]: files[0],
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        const form = new FormData();
        form.append('artifact_name', formData.artifact_name);
        form.append('artifact_description', formData.artifact_description);
        form.append('artifact_expiration_date', formData.artifact_expiration_date);
        form.append('artifact_owner', formData.artifact_owner);
        if (formData.artifact_file) {
            form.append('artifact_file', formData.artifact_file);
        }

        form.append('artifact_control', [controlID]);
        form.append('artifact_evidencerequest', [evidenceID]);

        const accountUUID = window.location.pathname.substring(
            window.location.pathname.indexOf("/company/") + "/company/".length,
            window.location.pathname.lastIndexOf("/")
        );

        const response = await fetch(
            // `${cysrConfig().ctrl_api_base_url}company/${accountUUID}/artifact${artifactsData && artifactsData.artifact_id ? "/" + artifactsData.artifact_id : ""}`
            `${cysrConfig().ctrl_api_base_url}company/${accountUUID}/artifact`
            , {
            body: form,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                // "content-type": "application/json",
            },
            method: 'POST',
            // method:  artifactsData ? 'PATCH' : 'POST',
        }).catch(x=>toast.error(JSON.stringify(x)));

        const result = await response?.json();
        if (result && result.Msg) {
            setCompanyEvidences(
                (prevItems) => [
                    ...prevItems,
                    {
                        "artifact_id": result.artifact_id,
                        "artifact_name": formData.artifact_name,
                        "artifact_description": formData.artifact_description,
                        "artifact_expiration_date": formData.artifact_expiration_date,
                        "artifact_filename": formData.artifact_file?.name,
                        "artifact_owner": formData.artifact_owner,
                        "artifact_control": [controlID],
                        "artifact_evidencerequest": [evidenceID]
                    }
                ]
            )
            toast.success(result.Msg);
            // reload control so to refresh
            loadControl(controlID)
        } else {
            toast.error(JSON.stringify(result));
        }

    };
    return (
        <>
            <div className="max-w-lg">
                <p className='mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content'>
                    {data.evidencerequest_id}
                </p>
                <h3 className="text-tremor-title font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    {
                        // data.evidencerequest_aof
                    }
                    {data.evidencerequest_documentation}
                </h3>
                <p className="mt-1 mb-2 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content">
                    {data.evidencerequest_description}
                </p>
                <Popover className="relative z-[99999]">
                    <PopoverButton type='button'>
                        <Button variant='light' className="outline-none text-left whitespace-nowrap truncate rounded-tremor-default focus:ring-2 transition duration-100 border h-8 px-3 py-1 shadow-tremor-input focus:border-tremor-brand-subtle focus:ring-tremor-brand-muted dark:shadow-dark-tremor-input dark:focus:border-dark-tremor-brand-subtle dark:focus:ring-dark-tremor-brand-muted pl-3 bg-tremor-background dark:bg-dark-tremor-background hover:bg-tremor-background-muted dark:hover:bg-dark-tremor-background-muted text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis border-tremor-border dark:border-dark-tremor-border" size='xs' icon={UploadSharp}>
                            Upload Artifact
                        </Button>
                    </PopoverButton>
                    <PopoverPanel className="max-w-lg w-full space-y-4 bg-turquoise-100 dark:bg-turquoise-900 shadow-lg p-4 rounded-lg z-[999999] ring-1 ring-tremor-ring dark:ring-dark-tremor-ring" style={{
                        position: 'fixed',
                        left: 0,
                        right: 0,
                        bottom: 'calc(50% - 200px)',
                        top: 'calc(50% - 200px)',
                        margin: 'auto'
                    }}>{({ close }) => (
                    <>
                        <div
                            align="end"
                            className="h-full flex flex-col gap-2 border-tremor-border dark:border-dark-tremor-border"
                        >
                            <p className='w-full text-left leading-6 text-tremor-default text-tremor-content dark:text-dark-tremor-content'>Upload Artifact for {data?.evidencerequest_id}</p>
                            <form className='h-full flex flex-col justify-between' onSubmit={(e)=>{
                                handleSubmit(e)
                                close()
                            }}>
                                <div className="max-h-[260px] overflow-scroll-y flex flex-col gap-2 items-end">
                                    <div className="space-y-6 w-full text-left">
                                        {false &&
                                            <div>
                                                <label
                                                    htmlFor="workspace-name"
                                                    className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
                                                >
                                                    Workspace
                                                    <span className="text-red-500">*</span>
                                                </label>
                                                <TextInput
                                                    type="text"
                                                    id="workspace-name"
                                                    name="workspace-name"
                                                    autoComplete="workspace-name"
                                                    placeholder="Workspace name"
                                                    className="mt-2"
                                                    required
                                                />
                                            </div>
                                        }
                                        <div>
                                            <label htmlFor="artifact_name"
                                                className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
                                            >Artifact Name:</label>
                                            <TextInput className="max-w-xs mt-2"
                                                type="text"
                                                id="artifact_name"
                                                name="artifact_name"
                                                //value={formData.artifact_name}
                                                onChange={handleChange}
                                                required
                                                minLength="1"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="artifact_description"
                                                className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
                                            >Artifact Description:</label>
                                            <TextInput className="max-w-xs mt-2"
                                                type="text"
                                                id="artifact_description"
                                                name="artifact_description"
                                                //value={formData.artifact_description}
                                                onChange={handleChange}
                                                required
                                                minLength="1"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="artifact_expiration_date"
                                                className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
                                            >Artifact Expiration Date:</label>
                                            <TextInput className="max-w-xs mt-2"
                                                type="date"
                                                id="artifact_expiration_date"
                                                name="artifact_expiration_date"
                                                //value={formData.artifact_expiration_date}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="artifact_file"
                                                className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
                                            >Artifact File:</label>
                                            <Input
                                                type="file"
                                                id="artifact_file"
                                                name="artifact_file"
                                                className="mt-2"
                                                accept=".csv, .doc, .docx, .jpg, .jpeg, .pdf, .png, .ppt, .pptx, .xls, .xlsx, .zip"
                                                onChange={handleChange}
                                            />
                                            {
                                                // currentFileName ? <p className="mt-2 text-tremor-label text-tremor-content dark:text-dark-tremor-content">Current file name: {currentFileName}</p> : null
                                            }
                                            {
                                                <p className="mt-2 text-xs text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                                                    Maximum 8MB file size. Only certain file types are permitted for upload.
                                                </p>
                                            }
                                        </div>
                                        <div>
                                            <label htmlFor="artifact_owner"
                                                className="text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong"
                                            >Artifact Owner:</label>
                                            <TextInput className="max-w-xs mt-2"
                                                type="text"
                                                id="artifact_owner"
                                                name="artifact_owner"
                                                // value={formData.artifact_owner}
                                                onChange={handleChange}
                                                required
                                                minLength="1"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-auto pt-4 flex items-center justify-between gap-3">
                                    <CloseButton>
                                        <Button variant='secondary' className="w-full text-white bg-turquoise-600">
                                            Cancel
                                        </Button>
                                    </CloseButton>
                                    <button
                                        type="submit"
                                        className="whitespace-nowrap rounded-tremor-small px-4 py-2 text-tremor-default font-medium text-white bg-turquoise-600"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div style={{
                            content: '',
                            background: 'hsl(194deg 12% 12% / 60%)',
                            position: 'fixed',
                            left: '-100px',
                            top: '-100px',
                            right: '-100px',
                            bottom: '-100px',
                            zIndex: '-1'
                        }} />
                        </>)}
                    </PopoverPanel>
                </Popover>
            </div>
        </>
    );
}