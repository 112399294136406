/* eslint-disable */
// prettier-ignore
// https://blog.logrocket.com/using-d3-js-v6-with-react/
import React, { useEffect, useRef, useState } from "react";
import cysrConfig, { process } from "layouts/cysr/config";
// import * as d3 from './d3v5';
// import d3 from "https://d3js.org/d3.v6.min.js"
import * as d3 from 'd3';
import './App.css';
// import graph from './data.json';
import { parse } from "tldjs"
import MDButton from "components/MDButton";
/*
function getSVGImg({ fill, assetType, color = "#ffffff" }) {
    var svgNS = 'http://www.w3.org/2000/svg';
    var svg = document.createElementNS(svgNS, 'svg');
    svg.setAttribute('width', '24');
    svg.setAttribute('height', '24');
    // icon color
    svg.setAttribute('fill', color);

    // add circle (with rating color) s
    let svgHTML = `<circle fill="${fill}" r="12" cy="12" cx="12"></circle>`;
    // add circle e
    switch (assetType) {

        case 'as':
            svgHTML += `<path d="m2.88 7.88 1.54 1.54C4.15 10.23 4 11.1 4 12c0 4.41 3.59 8 8 8s8-3.59 8-8-3.59-8-8-8c-.9 0-1.77.15-2.58.42L7.89 2.89C9.15 2.32 10.54 2 12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12c0-1.47.32-2.86.88-4.12zM7 5.5C7 6.33 6.33 7 5.5 7S4 6.33 4 5.5 4.67 4 5.5 4 7 4.67 7 5.5zm5.03 3.49h-.07L10.8 12.3h2.39l-1.16-3.31zM12 18c3.31 0 6-2.69 6-6s-2.69-6-6-6-6 2.69-6 6 2.69 6 6 6zm-.71-10.5h1.43l3.01 8h-1.39l-.72-2.04h-3.23l-.73 2.04H8.28l3.01-8z"></path>`;
            break;

        case 'dns':
            svgHTML += `<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.94-.49-7-3.85-7-7.93s3.05-7.44 7-7.93v15.86zm2-15.86c1.03.13 2 .45 2.87.93H13v-.93zM13 7h5.24c.25.31.48.65.68 1H13V7zm0 3h6.74c.08.33.15.66.19 1H13v-1zm0 9.93V19h2.87c-.87.48-1.84.8-2.87.93zM18.24 17H13v-1h5.92c-.2.35-.43.69-.68 1zm1.5-3H13v-1h6.93c-.04.34-.11.67-.19 1z"></path>`;
            break;

        case 'domain':
            svgHTML += `<path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3-8c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3z"></path>`;
            break;

        case 'email':
            svgHTML += `<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8 8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57V12c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47.65.89 1.77 1.47 2.96 1.47 1.97 0 3.5-1.6 3.5-3.57V12c0-5.52-4.48-10-10-10zm0 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"></path>`;
            break;

        case 'host':
            svgHTML += `<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4.5 14H8c-1.66 0-3-1.34-3-3s1.34-3 3-3h.14c.44-1.73 1.99-3 3.86-3 2.21 0 4 1.79 4 4h.5c1.38 0 2.5 1.12 2.5 2.5S17.88 16 16.5 16z"></path>`;
            break;

        case 'ip':
            // svgHTML += `<path d="M13.02 19.93v2.02c2.01-.2 3.84-1 5.32-2.21l-1.42-1.43c-1.11.86-2.44 1.44-3.9 1.62zM4.03 12c0-4.05 3.03-7.41 6.95-7.93V2.05C5.95 2.58 2.03 6.84 2.03 12c0 5.16 3.92 9.42 8.95 9.95v-2.02c-3.92-.52-6.95-3.88-6.95-7.93zm15.92-1h2.02c-.2-2.01-1-3.84-2.21-5.32l-1.43 1.43c.86 1.1 1.44 2.43 1.62 3.89zm-1.61-6.74c-1.48-1.21-3.32-2.01-5.32-2.21v2.02c1.46.18 2.79.76 3.9 1.62l1.42-1.43zm-.01 12.64 1.43 1.42c1.21-1.48 2.01-3.31 2.21-5.32h-2.02c-.18 1.46-.76 2.79-1.62 3.9z"></path><path d="M16 11.1C16 8.61 14.1 7 12 7s-4 1.61-4 4.1c0 1.66 1.33 3.63 4 5.9 2.67-2.27 4-4.24 4-5.9zm-4 .9c-.59 0-1.07-.48-1.07-1.07 0-.59.48-1.07 1.07-1.07s1.07.48 1.07 1.07c0 .59-.48 1.07-1.07 1.07z"></path>`;
            svgHTML += `<g transform="translate(3.5)">
       <g>
         <path class="st0" d="M9,22.3l-0.6-0.6c-2.7-2.3-4.8-4.5-6.2-6.6C0.7,13.1,0,11.1,0,9.2c0-2.8,0.9-5,2.7-6.7C4.5,0.8,6.6,0,9,0
           s4.5,0.8,6.3,2.5C17.1,4.2,18,6.4,18,9.2c0,1.9-0.7,3.9-2.2,6c-1.4,2-3.5,4.2-6.2,6.6L9,22.3z M9,2C7.1,2,5.5,2.6,4.1,4
           C2.7,5.3,2,7,2,9.2c0,1.5,0.6,3.1,1.8,4.9C5,15.8,6.7,17.7,9,19.7c2.3-2,4-3.9,5.2-5.6c1.2-1.8,1.8-3.4,1.8-4.9
           C16,7,15.3,5.3,13.9,4C12.5,2.6,10.9,2,9,2z"/>
       </g>
       <g>
         <path class="st0" d="M5,6.1h1.4V14H5V6.1z"/>
         <path class="st0" d="M10.6,6c1.1,0,2,0.2,2.6,0.6c0.6,0.4,0.9,1.1,0.9,2c0,0.5-0.1,0.9-0.2,1.2c-0.2,0.3-0.4,0.6-0.7,0.8
           c-0.3,0.2-0.7,0.4-1.1,0.5c-0.4,0.1-1,0.1-1.5,0.1H9.8V14H8.3V6.2C8.7,6.1,9,6.1,9.4,6C9.8,6,10.2,6,10.6,6z M10.7,7.2
           c-0.4,0-0.7,0-0.9,0V10h0.7c0.7,0,1.2-0.1,1.6-0.3c0.4-0.2,0.6-0.6,0.6-1.1c0-0.3,0-0.5-0.1-0.6c-0.1-0.2-0.2-0.3-0.4-0.4
           c-0.2-0.1-0.4-0.2-0.6-0.2C11.2,7.3,10.9,7.2,10.7,7.2z"/>
       </g>
     </g>`;
            break;

        case 'network':
            svgHTML += `<path d="M10 9c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zM7 9.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm3 7c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm-3-3c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm3-6c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zM14 9c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-1.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm3 6c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-4c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm2-3.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-3.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z"></path>`;
            break;

        case 'website':
            svgHTML += `<path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"></path>`;
            break;

        default:
            svgHTML += `<path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>`;
            break;
    }
    svg.innerHTML = svgHTML;

    // Convert the SVG element to an image
    var svgAsXML = new XMLSerializer().serializeToString(svg);
    var img = new Image();
    img.src = 'data:image/svg+xml,' + encodeURIComponent(svgAsXML);
    return img
}
*/

// funzioni per convertire data in solar s
function trasformaIP(string) {
    // Seleziona solo la parte dell'indirizzo IP, escludendo una potenziale subnet mask
    let ip = string.split('/')[0];

    // Controlla se l'indirizzo è in formato IPv4 o IPv6
    if (ip.includes('.')) { // Gestione IPv4
        let ottetti = ip.split('.');
        ottetti[1] = '0'; //messo per gestire esclusivamente /8
        ottetti[2] = '0';
        ottetti[3] = '0';
        return ottetti.join('.').replace(/[.:]/g, '_');
    } else if (ip.includes(':')) { // Gestione IPv6
        let gruppi = ip.split(':');
        // Assicurati che ci siano 8 gruppi, espandendo eventuali abbreviazioni
        let gruppiEspansi = gruppi.map(gruppo => gruppo.padStart(4, '0'));
        // Azzerare tutto tranne i primi due gruppi per semplificare, potresti voler adottare una strategia diversa
        let risultato = gruppiEspansi.slice(0, 2).concat(Array(6).fill('0000'));
        return risultato.join(':').replace(/[.:]/g, '_');
    } else {
        return 'other_ip';
    }
}

function getAssetId(type, string) {
    return type.toLowerCase().trim() + "_" + trasformaIP(string).toLowerCase().trim();
}

function trovaAsCollegatoELabel(networkId, arrows, idToNode) {
    for (let arrow of arrows) {
        if (arrow.from === networkId && idToNode[arrow.to]?.group === 'as') {
            const asId = idToNode[arrow.to].id;
            const asLabel = idToNode[arrow.to].label;
            return idToNode[asId];
        }
    }
    return false;
}

function trovaNetworkCollegataELabel(ipId, arrows, idToNode) {

    for (let arrow of arrows) {
        //if (arrow.from === ipId && idToNode[arrow.to]?.group === 'network') {
        if (arrow.from === ipId && idToNode[arrow.to].group === 'network') {


            const networkId = arrow.to;

            if (networkId in idToNode) {

                return idToNode[networkId];
            }
        }
    }
    return false;
}


function creaNodoAs(asId, asLabel) {
    return {
        group: "as",
        id: asId,
        label: asLabel,
        solar: asId,
        solar_label: asLabel
    };
}

function creaNodoNetwork(networkId, networkLabel, asId) {
    return {
        group: "network",
        id: networkId,
        label: networkLabel,
        solar: asId,
        solar_label: networkLabel
    };
}




function aggiungiCampoSolar(networkJson) {
    const dominioIdMap = {};
    const nuoviNodi = []; // Lista per tenere traccia dei nuovi nodi "domain" aggiunti
    const nuoviArrows = []; // Lista per tenere traccia dei nuovi collegamenti aggiunti
    const hashPromises = []; // Memorizza le promesse per il calcolo degli hash

    networkJson.nodes.forEach(nodo => {
        if (nodo.group === 'domain') {
            const label = nodo.label;
            const estratto = parse(label);
            const dominio = `${estratto.domain}`;
            if (dominio === label) {
                dominioIdMap[dominio] = nodo.id;
            }
        }
    });

    // Preparare le promesse per calcolare gli hash dove necessario
    networkJson.nodes.forEach(nodo => {
        const label = nodo.label;
        const tipo = nodo.group;

        if (['domain', 'email', 'dns', 'host', 'website', 'api'].includes(tipo)) {
            const estratto = parse(label);
            const dominio = `${estratto.domain}`;
            nodo.solar_label = dominio;

            if ((dominio in dominioIdMap)) {
                nodo.solar = dominioIdMap[dominio];
            }
            else {
                //if (!(dominio in dominioIdMap)) {
                // Inserisci la promessa nell'array
                // EDIT
                const dominio_id = dominio.replace(/[.:]/g, '_');

                const nuovoIdDominio = `domain_${dominio_id}`;
                dominioIdMap[dominio] = nuovoIdDominio;

                nodo.solar = nuovoIdDominio;

                // Crea il nuovo nodo "domain" e il collegamento
                nuoviNodi.push({
                    group: "domain",
                    id: nuovoIdDominio,
                    label: dominio,
                    shape: "box",
                    status: "ENABLED",
                    origin: "generated",
                    depth: 0,
                    rating_val: 0,
                    solar_label: dominio,
                    solar: nuovoIdDominio
                });

                // Crea il collegamento dal nuovo nodo "domain" al nodo attuale
                nuoviArrows.push({
                    from: nuovoIdDominio,
                    to: nodo.id,
                    arrows: "to"
                });


            }

        }
        // EDIT
        const idToNode = Object.fromEntries([...networkJson.nodes, ...nuoviNodi].map(nodo => [nodo.id, nodo]));
        const networkSolarMap = {};
        if (nodo.group === 'network') {

            // EDIT
            const as_trovato = trovaAsCollegatoELabel(nodo.id, [...networkJson.arrows, ...nuoviArrows], idToNode);
            // EDIT
            const asCollegato = as_trovato.id
            // EDIT
            const asLabel = as_trovato.label

            if (as_trovato) {
                networkSolarMap[nodo.id] = [asCollegato, asLabel];
                nodo.solar = asCollegato;
                nodo.solar_label = asLabel;
            } else {
                const nuovoAsId = getAssetId("as", nodo.label)
                const nuovoAsLabel = "AS Label " + nuovoAsId;
                networkSolarMap[nodo.id] = [nuovoAsId, nuovoAsLabel];
                nodo.solar = nuovoAsId;
                nodo.solar_label = nuovoAsLabel;

                nuoviNodi.push(creaNodoAs(nuovoAsId, nuovoAsLabel));
                nuoviArrows.push({
                    from: nodo.id,
                    to: nuovoAsId,
                    arrows: "to"
                });



            }
        }

        if (nodo.group === 'ip') {

            const networkCollegata = trovaNetworkCollegataELabel(nodo.id, [...networkJson.arrows, ...nuoviArrows], idToNode);

            if (networkCollegata) {


                //codice copiato sopra, ottimizzare

                // EDIT
                const as_trovato = trovaAsCollegatoELabel(networkCollegata.id, [...networkJson.arrows, ...nuoviArrows], idToNode);
                // EDIT
                const asCollegato = as_trovato.id;
                // EDIT
                const asLabel = as_trovato.label;
                //TODO VERIFICARE


                if (as_trovato) {
                    networkSolarMap[nodo.id] = [asCollegato, asLabel];
                    nodo.solar = asCollegato;
                    nodo.solar_label = asLabel;

                } else {


                    const nuovoAsId = getAssetId("as", nodo.label)
                    const nuovoAsLabel = "AS Label " + nuovoAsId;
                    networkSolarMap[nodo.id] = [nuovoAsId, nuovoAsLabel];
                    nodo.solar = nuovoAsId;
                    nodo.solar_label = nuovoAsLabel;

                    nuoviNodi.push(creaNodoAs(nuovoAsId, nuovoAsLabel));
                    nuoviArrows.push({
                        from: networkCollegata.id,
                        to: nuovoAsId,
                        arrows: "to"
                    });

                }



            } else {


                const nuovoNetworkId = getAssetId("network", nodo.label);
                const nuovoNetworkLabel = "Network Label " + nuovoNetworkId;
                const nuovoAsId = getAssetId("as", nodo.label);
                const nuovoAsLabel = "AS Label " + nuovoAsId;

                nodo.solar = nuovoAsId;
                nodo.solar_label = nuovoAsLabel;

                if (!(nuovoNetworkId in idToNode)) {

                    nuoviNodi.push(creaNodoNetwork(nuovoNetworkId, nuovoNetworkLabel, nuovoAsId));
                    nuoviArrows.push({
                        from: nodo.id,
                        to: nuovoNetworkId,
                        arrows: "to"
                    });
                }

                if (!(nuovoAsId in idToNode)) {
                    nuoviNodi.push(creaNodoAs(nuovoAsId, nuovoAsLabel));
                    nuoviArrows.push({
                        from: nuovoNetworkId,
                        to: nuovoAsId,
                        arrows: "to"
                    });



                }
            }
        }


        if (nodo.group === 'as') {
            nodo.solar = nodo.id;
            nodo.solar_label = nodo.label;
        }



    });

    networkJson.nodes = networkJson.nodes.concat(nuoviNodi);
    networkJson.arrows = networkJson.arrows.concat(nuoviArrows);




    let conteggiDomain = {}; // Oggetto per tenere traccia dei conteggi per tipo di nodo collegato a ciascun dominio
    let conteggiAs = {}; // Oggetto per tenere traccia dei conteggi per tipo di nodo collegato a ciascun AS
    let conteggioInNodo = {};
    let conteggiTarget = {};
    let mapNode = {};

    networkJson.nodes.forEach(nodo => {
        let tipo = nodo.group;
        let solar = nodo.solar; // Ottiene l'ID del solar di riferimento

        // Inizializza l'oggetto interno per il solar se non esiste

        if (!(solar in conteggiTarget)) {
            conteggiTarget[solar] = { email: 0, domain: 0, dns: 0, host: 0, website: 0, ip: 0, network: 0, total: 0 };
        }

        // Incrementa il conteggio per il tipo di nodo
        if (tipo in conteggiTarget[solar]) {
            conteggiTarget[solar][tipo] += 1;
        }

        conteggiTarget[solar].total += 1;
        mapNode[nodo.id] = nodo;
    });

    networkJson.nodes.forEach(nodo => {
        // Ottiene l'ID del solar di riferimento
        let solar = nodo.solar;

        nodo.solar_type = "planet";
        // Controlla se l'ID del nodo corrisponde a `solar` e se i conteggi per quel `solar` esistono
        if (nodo.id === solar && conteggiTarget[solar]) {
            // Assegna i conteggi trovati in `conteggiTarget` per il solar corrente al nodo
            // sotto la nuova chiave `solar_count`
            nodo.solar_count = conteggiTarget[solar];
            nodo.solar_type = "star"; //center of the star system :)
        }
    });



    const mapNodeCount = {};
    networkJson.arrows.forEach(link => {
        const aFrom = link.from;
        const aTo = link.to;
        const solarIdFrom = mapNode[aFrom].solar;
        const solarIdTo = mapNode[aTo].solar;



        if (solarIdFrom !== solarIdTo) {
            if (!(solarIdFrom in mapNodeCount)) {
                mapNodeCount[solarIdFrom] = {};
                mapNodeCount[solarIdFrom][solarIdTo] = 1;
            } else if (!(solarIdTo in mapNodeCount[solarIdFrom])) {
                mapNodeCount[solarIdFrom][solarIdTo] = 1;
            } else {
                mapNodeCount[solarIdFrom][solarIdTo] += 1;
            }
        }
    });

    const transformedList = [];
    Object.entries(mapNodeCount).forEach(([source, targets]) => {
        Object.entries(targets).forEach(([target, weight]) => {
            transformedList.push({
                source,
                target,
                weight
            });
        });

    });


    networkJson['solar_links'] = transformedList;
    networkJson['solar_nodes'] = networkJson.nodes.filter(nodo => nodo.solar_type === "star");

    return networkJson;
}
// funzioni per convertire data in solar e

export default function ChartSolar({ dataGraphRaw, darkMode = true }) {
    const [dimensions, setDimensions] = useState({ height: 0, width: 0 })
    const [solarFunction, setSolarFunctions] = useState({})
    const svgRef = useRef(null)
    // using data.data
    // added this p1/3 s
    const accountUUID = window.location.pathname.substring(
        window.location.pathname.indexOf("/company/") + "/company/".length,
        window.location.pathname.lastIndexOf("/")
    );
    // let darkMode = false;
    // if (dataGraph.darkMode) {
    //     darkMode = dataGraph.darkMode
    // }

    // Trasforma i dati con `aggiungiCampoSolar`
    const graph = aggiungiCampoSolar(dataGraphRaw)
    // return aggiungiCampoSolar(data); // Assicurati che `aggiungiCampoSolar` restituisca il nuovo `graph`


    // added this p1/3 e
    useEffect(() => {

        //const width = 3840, height = 2160; // Dimensioni dell'SVG
        // const width = 1920, height = 2160; // Dimensioni dell'SVG

        // const width = 1920, height = 1920; // Dimensioni dell'SVG
        // const svg = d3.select('#sol-viz').append('svg').attr('width', width).attr('height', height);

        const svg = d3.select(svgRef.current);
        // const width = svg.node().parentElement.clientWidth; // Get the container width
        // const height = width// svg.node().parentElement.clientHeight; // Get the container width
        // const height = 500; // Or any fixed height you prefer

        const spacing = 120;
        // const width = svg.node().parentElement.clientWidth + spacing; // Get the container width - 20; // Lascia un piccolo margine o spazio per altri elementi
        // const height = width + spacing; // svg.node().parentElement.clientHeight; // Get the container width - 20; // Lascia un piccolo margine o spazio per altri elementi

        const width = document.getElementById("take_width_size_solar").offsetWidth;
        const height = Math.min(window.innerHeight, width);

        setDimensions({ width, height });

        const g = svg.append("g").attr("transform", "translate(0,0)"); // Reset transform

        const tooltip = d3.select("body").append("div").attr("class", "tooltip");
        const solarMap = new Map(graph.solar_nodes.map(s => [s.solar, s]));

        // up s
        const doSimulation = false;
        let zoom_level = 1;

        // const width = window.innerWidth - 20; // Lascia un piccolo margine o spazio per altri elementi
        // const height = window.innerHeight - 20;
        // const svg = d3.select('body').append('svg').attr('width', width).attr('height', height);
        // const g = svg.append("g");
        // const tooltip = d3.select("body").append("div").attr("class", "tooltip");
        // const solarMap = new Map(graph.solar_nodes.map(s => [s.solar, s]));

        let circleGroup = g.select('.circle-group');
        if (circleGroup.empty()) {
            circleGroup = g.append('g').attr('class', 'circle-group');
        }

        // Crea un gruppo per i nodi se non esiste già all'interno di `g`
        let nodeGroup = g.select('.node-group');
        if (nodeGroup.empty()) {
            nodeGroup = g.append('g').attr('class', 'node-group');
        }




        const zoomHandler = d3.zoom()
            .on("zoom", (event) => {
                g.attr("transform", event.transform);
                g.selectAll(".label-group")
                    .attr("transform", d => `translate(${d.x},${d.y}) scale(${1 / event.transform.k})`);
            });

        svg.call(zoomHandler)
            .on("wheel", function (event) {
                if (event.ctrlKey || event.shiftKey) {
                    event.preventDefault();  // Blocca il comportamento predefinito del browser
                    zoomHandler(event);     // Attiva lo zoom se CTRL o SHIFT sono premuti
                }
            });

        // Configura il filtro per accettare eventi solo con CTRL o SHIFT premuti
        zoomHandler.filter(event => {
            if (event.type === 'wheel') {
                return event.ctrlKey || event.shiftKey;
            }
            return !event.button;
        });

        var links = graph.arrows.map(d => ({ source: d.from, target: d.to }));
        var solar_links = graph.solar_links;
        var nodes = graph.nodes;
        var nodes_map = new Map(graph.nodes.map(s => [s.id, s]));
        //const solar_type_list = ['domain', 'as','AS'];
        const solar_type_list = ["Domain", 'domain', 'as', 'AS'];
        //const solar_type_list = ["Domain",'domain', 'as','AS','ip','network','website','host',"Network","dns","email"];
        const solar_type_list_domain = ['domain'];
        const solar_type_list_as = ['as', 'AS'];

        var type_distances = {
            "dns": .2,
            "email": .4,
            "domain": .6,
            "host": .8,
            "api": 1,
            "website": 1,
            "as": .1,
            "AS": .1,
            "network": .5,
            "Network": .5,
            "ip": 1,
            "max": 1
        };

        var mu_var = {
            "mu_force_link_generic_count": 1.0,
            "mu_force_link_generic_fix": 20,
            "mu_force_link_as_add_count": 0,
            "mu_force_link_as_add_fix": 0,
            "mu_force_link_domain_add_count": 0,
            "mu_force_link_domain_add_fix": 0,
            "mu_force_link_default": 0,
            "mu_force_charge_domain_add_count": 5,
            "mu_force_charge_domain_add_fix": -4000,
            "mu_force_charge_as_add_count": 5,
            "mu_force_charge_as_add_fix": -4000,
            "mu_force_charge_other_add_count": 0,
            "mu_force_charge_other_add_fix": -1,
            "mu_force_charge_default": 0,
            "mu_circle_base_distance": 0,
            "mu_circle_count": .5,
            "mu_circle_r_prop": 1 / 5,
            "mu_circle_r_x": 100,
            "mu_circle_r_min": 1.5,
            "mu_distance_force_mul": .01,
            "mu_distance_force_sum": .1,
            "mu_distance_charge_mul": -2,
            "mu_distance_charge_sum": -500
        }



        var forceConf = {}


        links = [...links, ...solar_links];


        // Definizione della simulazione con D3.js
        const simulation = d3.forceSimulation(nodes)


            .force('link', d3.forceLink(links).id(d => d.id).distance((link) => {
                var linkValue = 0;

                if (solar_type_list.includes(nodes_map.get(link.source.id)['group']) && solar_type_list.includes(nodes_map.get(link.target.id)['group']) && nodes_map.get(link.source.id).solar_count && nodes_map.get(link.target.id).solar_count) {

                    linkValue = (nodes_map.get(link.source.id).solar_count.total + nodes_map.get(link.target.id).solar_count.total) * mu_var.mu_force_link_generic_count + mu_var.mu_force_link_generic_fix; //VAR mu_force_link_generic_count, mu_force_link_generic_fix


                    if (solar_type_list_as.includes(nodes_map.get(link.source.id)['group']) || solar_type_list_as.includes(nodes_map.get(link.target.id)['group'])) {

                        linkValue += (nodes_map.get(link.source.id).solar_count.total + nodes_map.get(link.target.id).solar_count.total) * mu_var.mu_force_link_as_add_count + mu_var.mu_force_link_as_add_fix; //VAR mu_force_link_as_add_count, mu_force_link_as_add_fix
                    }
                    if (solar_type_list_domain.includes(nodes_map.get(link.source.id)['group']) || solar_type_list_domain.includes(nodes_map.get(link.target.id)['group'])) {

                        linkValue += (nodes_map.get(link.source.id).solar_count.total + nodes_map.get(link.target.id).solar_count.total) * mu_var.mu_force_link_domain_add_count + mu_var.mu_force_link_domain_add_fix  //VAR mu_force_link_domain_add_count, mu_force_link_domain_add_fix
                    }

                }
                else {


                    linkValue = mu_var.mu_force_link_default;  //VAR mu_force_link_default

                }





                return linkValue;

            }))
            .force('charge', d3.forceManyBody().strength((d) => {
                let additionalRepulsion = 0;

                if (d.solar && nodes_map.get(d.solar)) {
                    if (solar_type_list_domain.includes(nodes_map.get(d.id)['group'])) {

                        additionalRepulsion = nodes_map.get(d.solar).solar_count.total * mu_var.mu_force_charge_domain_add_count + mu_var.mu_force_charge_domain_add_fix; //VAR mu_force_charge_domain_add_count, mu_force_charge_domain_add_fix                            
                    }
                    else if (solar_type_list_as.includes(nodes_map.get(d.id)['group'])) {

                        additionalRepulsion = nodes_map.get(d.solar).solar_count.total * mu_var.mu_force_charge_as_add_count + mu_var.mu_force_charge_as_add_fix; //VAR mu_force_charge_as_add_count, mu_force_charge_as_add_fix                                
                    }

                    else {


                        additionalRepulsion = nodes_map.get(d.solar).solar_count.total * mu_var.mu_force_charge_other_add_count + mu_var.mu_force_charge_other_add_fix; //VAR mu_force_charge_other_add_count, mu_force_charge_other_add_fix  
                        //EDIT
                        // mu_force_charge_default = 0;
                        additionalRepulsion = 0;

                    }
                }


                if (mu_var.mu_force_charge_default + additionalRepulsion != 0) {

                }



                return mu_var.mu_force_charge_default + additionalRepulsion; //VAR mu_force_charge_default

            }))
            .force('center', d3.forceCenter(width / 2, height / 2))
            .force('custom', applyCustomForces); // Aggiunta della forza personalizzata

        //var link = g.append('g').attr('class', 'links').selectAll('line').data(links).enter().append('line');
        var link = g.append('g').attr('class', 'links').selectAll('line')
            .data(links).enter().append('line')
            .attr('class', d => `link source-${d.source.id} target-${d.target.id}`); // Aggiungi classi per identificazione




        var node = g.append('g')
            .attr('class', 'nodes')
            .selectAll('.node')
            .data(nodes)
            .enter()
            .append('g') // Usa 'g' per raggruppare l'icona e permettere più trasformazioni/interazioni
            .attr('class', 'node')
            .on("mouseover", (event, d) => {
                // Gestione del mouseover...
                tooltip.html(`${d.group}: ${d.label}`)
                    .style("left", `${event.pageX + 10}px`)
                    .style("top", `${event.pageY + 10}px`)
                    .style("background", "#000")
                    .style("display", "inline");
            })
            .on("mouseout", () => {
                // Gestione del mouseout...
                tooltip.style("display", "none");
            });

        // Per ogni nodo, scegliamo un'icona SVG in base al tipo
        node.each(function (d) {
            let group = d3.select(this); // Seleziona il gruppo corrente
            let svgHTML; // Qui memorizzeremo l'SVG come stringa HTML

            // Calcola il fattore di scala basato su "rating_val"
            // Assumendo che il "rating_val" sia un numero tra 0 e 100
            let ratingVal = d.rating_val; // Assicurati che "rating_val" sia effettivamente nel tuo dato


            // Imposta le dimensioni e la posizione del cerchio di sfondo.
            // Assicurati che il raggio sia sufficientemente grande da coprire l'intera icona.
            let circleCX = 12; // Posizione X del centro del cerchio (relativa al centro dell'icona)
            let circleCY = 12; // Posizione Y del centro del cerchio (relativa al centro dell'icona)
            let circleR = 10; // Raggio del cerchio di sfondo
            //let bgColor = "#f0f0f0";
            let bgColor = chooseColorByRatingBG(d.rating_val);


            switch (d.group) { // Sostituisci 'type' con la proprietà corretta che indica il tipo di nodo
                case 'AS':
                    svgHTML = `<circle cx="${circleCX}" cy="${circleCY}" r="${circleR}" fill="${bgColor}"></circle>` + `<path d="m2.88 7.88 1.54 1.54C4.15 10.23 4 11.1 4 12c0 4.41 3.59 8 8 8s8-3.59 8-8-3.59-8-8-8c-.9 0-1.77.15-2.58.42L7.89 2.89C9.15 2.32 10.54 2 12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12c0-1.47.32-2.86.88-4.12zM7 5.5C7 6.33 6.33 7 5.5 7S4 6.33 4 5.5 4.67 4 5.5 4 7 4.67 7 5.5zm5.03 3.49h-.07L10.8 12.3h2.39l-1.16-3.31zM12 18c3.31 0 6-2.69 6-6s-2.69-6-6-6-6 2.69-6 6 2.69 6 6 6zm-.71-10.5h1.43l3.01 8h-1.39l-.72-2.04h-3.23l-.73 2.04H8.28l3.01-8z" fill="${chooseColorByRating(d.rating_val)}"></path>`; // Completa con il tuo SVG
                    break;
                case 'as':
                    svgHTML = `<circle cx="${circleCX}" cy="${circleCY}" r="${circleR}" fill="${bgColor}"></circle>` + `<path d="m2.88 7.88 1.54 1.54C4.15 10.23 4 11.1 4 12c0 4.41 3.59 8 8 8s8-3.59 8-8-3.59-8-8-8c-.9 0-1.77.15-2.58.42L7.89 2.89C9.15 2.32 10.54 2 12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12c0-1.47.32-2.86.88-4.12zM7 5.5C7 6.33 6.33 7 5.5 7S4 6.33 4 5.5 4.67 4 5.5 4 7 4.67 7 5.5zm5.03 3.49h-.07L10.8 12.3h2.39l-1.16-3.31zM12 18c3.31 0 6-2.69 6-6s-2.69-6-6-6-6 2.69-6 6 2.69 6 6 6zm-.71-10.5h1.43l3.01 8h-1.39l-.72-2.04h-3.23l-.73 2.04H8.28l3.01-8z" fill="${chooseColorByRating(d.rating_val)}"></path>`; // Completa con il tuo SVG
                    break;
                case 'dns':
                    svgHTML = `<circle cx="${circleCX}" cy="${circleCY}" r="${circleR}" fill="${bgColor}"></circle>` + `<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.94-.49-7-3.85-7-7.93s3.05-7.44 7-7.93v15.86zm2-15.86c1.03.13 2 .45 2.87.93H13v-.93zM13 7h5.24c.25.31.48.65.68 1H13V7zm0 3h6.74c.08.33.15.66.19 1H13v-1zm0 9.93V19h2.87c-.87.48-1.84.8-2.87.93zM18.24 17H13v-1h5.92c-.2.35-.43.69-.68 1zm1.5-3H13v-1h6.93c-.04.34-.11.67-.19 1z" fill="${chooseColorByRating(d.rating_val)}"></path>`; // Completa con il tuo SVG
                    break;
                case 'Network':
                    svgHTML += `<circle cx="${circleCX}" cy="${circleCY}" r="${circleR}" fill="${bgColor}"></circle>` + `<path d="M10 9c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zM7 9.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm3 7c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm-3-3c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm3-6c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zM14 9c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-1.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm3 6c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-4c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm2-3.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-3.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z" fill="${chooseColorByRating(d.rating_val)}"></path>`;
                    break;
                case 'network':
                    svgHTML += `<circle cx="${circleCX}" cy="${circleCY}" r="${circleR}" fill="${bgColor}"></circle>` + `<path d="M10 9c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zM7 9.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm3 7c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm-3-3c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm3-6c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zM14 9c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-1.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm3 6c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-4c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm2-3.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-3.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z" fill="${chooseColorByRating(d.rating_val)}"></path>`;
                    break;
                case 'domain':
                    svgHTML += `<circle cx="${circleCX}" cy="${circleCY}" r="${circleR}" fill="${bgColor}"></circle>` +
                        `<path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3-8c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3z" fill="${chooseColorByRating(d.rating_val)}"></path>`;
                    //console.log("domain",d.rating_val);
                    break;
                case 'host':
                    svgHTML += `<circle cx="${circleCX}" cy="${circleCY}" r="${circleR}" fill="${bgColor}"></circle>` + `<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4.5 14H8c-1.66 0-3-1.34-3-3s1.34-3 3-3h.14c.44-1.73 1.99-3 3.86-3 2.21 0 4 1.79 4 4h.5c1.38 0 2.5 1.12 2.5 2.5S17.88 16 16.5 16z" fill="${chooseColorByRating(d.rating_val)}"></path>`;
                    break;
                case 'website':
                    svgHTML += `<circle cx="${circleCX}" cy="${circleCY}" r="${circleR}" fill="${bgColor}"></circle>` + `<path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z" fill="${chooseColorByRating(d.rating_val)}"></path>`;
                    break;
                case 'ip':
                    svgHTML += `<circle cx="${circleCX}" cy="${circleCY}" r="${circleR}" fill="${bgColor}"></circle>` + `
          
                <path d="M 12,2 C 6.49,2 2,6.49 2,12 2,17.51 6.49,22 12,22 17.51,22 22,17.51 22,12 22,6.49 17.51,2 12,2 Z m 0,18 C 7.59,20 4,16.41 4,12 4,7.59 7.59,4 12,4 c 4.41,0 8,3.59 8,8 0,4.41 -3.59,8 -8,8 z" fill="${chooseColorByRating(d.rating_val)}"></path>
                <g transform="translate(3.4,1.6)"><g>
                <g>
                    <path class="st0" d="M5,6.1h1.4V14H5V6.1z" fill="${chooseColorByRating(d.rating_val)}"/>
                    <path class="st0" d="M10.6,6c1.1,0,2,0.2,2.6,0.6c0.6,0.4,0.9,1.1,0.9,2c0,0.5-0.1,0.9-0.2,1.2c-0.2,0.3-0.4,0.6-0.7,0.8
                    c-0.3,0.2-0.7,0.4-1.1,0.5c-0.4,0.1-1,0.1-1.5,0.1H9.8V14H8.3V6.2C8.7,6.1,9,6.1,9.4,6C9.8,6,10.2,6,10.6,6z M10.7,7.2
                    c-0.4,0-0.7,0-0.9,0V10h0.7c0.7,0,1.2-0.1,1.6-0.3c0.4-0.2,0.6-0.6,0.6-1.1c0-0.3,0-0.5-0.1-0.6c-0.1-0.2-0.2-0.3-0.4-0.4
                    c-0.2-0.1-0.4-0.2-0.6-0.2C11.2,7.3,10.9,7.2,10.7,7.2z" fill="${chooseColorByRating(d.rating_val)}"/>
                </g>`;
                    break;

                case 'email':
                    svgHTML += `<circle cx="${circleCX}" cy="${circleCY}" r="${circleR}" fill="${bgColor}"></circle>` + `<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8 8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57V12c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47.65.89 1.77 1.47 2.96 1.47 1.97 0 3.5-1.6 3.5-3.57V12c0-5.52-4.48-10-10-10zm0 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z" fill="${chooseColorByRating(d.rating_val)}"></path>`;
                    break;
                // Aggiungi altri casi qui per i diversi tipi di nodo
                default:
                    svgHTML = `<circle r="5" fill="${chooseColorByRating(d.rating_val)}"></circle>`; // Fallback a cerchio se il tipo non è riconosciuto
                    break;
            }

            group.html(svgHTML);
            let scale = calculateScale(d.rating_val || 100);
            group.attr('transform', `scale(${scale})`);

        });
        //console.log(graph)

        if (doSimulation == 'yes') {

            simulation
                .alpha(1) // Imposta l'alpha iniziale a 1 (default 1)
                .alphaDecay(0.03) // Velocizza il decadimento di alpha (default ~0.0228)
                .alphaTarget(0) // Imposta un target per alpha a cui la simulazione si ferma (default 0)
                .on('tick', ticked)
                .on('end', () => {

                    console.log('simulation end');

                });
        }
        else {
            const totalIterations = 300; // Numero di iterazioni, adattalo in base alle necessità
            for (let i = 0; i < totalIterations; ++i) {
                simulation.tick();
            }
            updateCircles();

            ticked(); // Chiama la funzione ticked una volta per applicare le posizioni finali                     

            simulation.stop();

        }


        // DEFAULT 1. Farei vedere l'etichetta degli asset al centro - Mostra le etichette per i nodi al centro
        function showDefaultCenterLabels(g) {
            g.selectAll(".label-group")
                .attr("transform", d => {
                    return `translate(${d.x},${d.y}) scale(${1 / zoom_level})`;
                });
            labelGroups.style("display", d => (d.solar_type === "star") ? null : "none");
        }
        // Mostra le etichette per i nodi con solar_type="star"
        function showSolarLabels(g) {
            g.selectAll(".label-group")
                .attr("transform", d => {
                    return `translate(${d.x},${d.y}) scale(${1 / zoom_level})`;
                });
            labelGroups.style("display", d => d.solar_type === "star" ? null : "none");
        }

        // Mostra le etichette per i nodi con rating_val < 30
        function showLowRatingLabels() {
            g.selectAll(".label-group")
                .attr("transform", d => {
                    return `translate(${d.x},${d.y}) scale(${1 / zoom_level})`;
                });
            labelGroups.style("display", d => (d.rating_val > 0 && d.rating_val < 70) ? null : "none");
        }

        // Nascondi tutte le etichette
        function hideAllLabels() {
            labelGroups.style("display", "none");
        }


        function ticked() {

            node.attr("transform", d => {
                let scale = calculateScale(d.rating_val || 100); // Calcola nuovamente la scala in caso di aggiornamenti
                return `translate(${d.x - 24 * scale / 2},${d.y - 24 * scale / 2}) scale(${scale})`; // Applica sia la traslazione che la scala
            });
            // Aggiorna la posizione dei link
            link.attr('x1', d => d.source.x)
                .attr('y1', d => d.source.y)
                .attr('x2', d => d.target.x)
                .attr('y2', d => d.target.y);

            // Aggiorna la posizione dei nodi
            node.attr('cx', d => d.x)
                .attr('cy', d => d.y);
            updateCircles(); // Aggiorna la posizione dei cerchi
            //labels.attr("x", d => d.x).attr("y", d => d.y);

            // Aggiorna o crea le etichette per i nodi 'domain' e 'as'
            var labelGroups = g.selectAll(".label-group")

            labelGroups.attr("x", d => d.x).attr("y", d => d.y);

            nodes.forEach(d => {
                if ((d.group === 'domain' && d.id === d.solar) || d.group === 'as') {


                    g.selectAll(".label-group")
                        .attr("transform", d => {
                            return `translate(${d.x},${d.y}) scale(${1 / zoom_level})`;
                        });

                }
            });
        }

        // Crea un gruppo per ciascuna etichetta
        var labelGroups = g.selectAll(".label-group")
            .data(nodes)
            .enter().append("g")
            .attr("class", "label-group")
            .style("display", "none")
            .style("opacity", 0.90); // Opacità dello sfondo
        //.style("display", "none")

        // Aggiungi un rettangolo di sfondo a ciascun gruppo
        labelGroups.append("rect")
            .attr("class", "label-background")
            .style("fill", "#555") // Colore di sfondo
            .style("opacity", 0.90); // Opacità dello sfondo    

        // Aggiungi il testo dell'etichetta a ciascun gruppo
        labelGroups.append("text")
            .attr("class", "label")
            .attr("dx", 12)
            .attr("dy", ".35em")
            .text(d => d.group + ": " + d.label)
            .style("fill", "#fff")
            //.style("display", "none")
            .call(getTextSize); // Funzione per aggiornare le dimensioni del background basate sul testo

        // Funzione per calcolare e aggiornare le dimensioni del rettangolo di sfondo
        function getTextSize(selection) {
            selection.each(function (d) {
                var bbox = this.getBBox();
                var padding = 2; // Imposta un padding per il background
                d3.select(this.parentNode).select("rect")
                    .attr("x", bbox.x - padding)
                    .attr("y", bbox.y - padding)
                    .attr("width", bbox.width + padding * 2)
                    .attr("height", bbox.height + padding * 2);
            });
        }


        setSolarFunctions({
            showDefaultCenterLabels: () => showDefaultCenterLabels(g),
            showSolarLabels: () => showSolarLabels(g),
            showLowRatingLabels: () => showLowRatingLabels(g),
            hideAllLabels: () => hideAllLabels(g),
        })
        // d3.select("#showSolarLabels").on("click", showSolarLabels);
        // d3.select("#showLowRatingLabels").on("click", showLowRatingLabels);
        // d3.select("#hideAllLabels").on("click", hideAllLabels);
        // toggle by defult etichette
        // showSolarLabels(g)
        // showLowRatingLabels(g)
        // hideAllLabels(g)

        showDefaultCenterLabels(g)

        // Aggiungi gestore click ai nodi per evidenziare i link
        node.on("mouseover", (event, d) => {
            // Evidenzia i link diretti
            d3.selectAll(`.link.source-${d.id}, .link.target-${d.id}`)
                .style('stroke-opacity', 1)
                .style('stroke', 'white')
                .style('stroke-width', '2px'); // Rendi i link più spessi per evidenziarli

            //  labels.filter(node => node.id === d.id)
            //      .style("display", null);
            // labels.attr("x", d => d.x).attr("y", d => d.y);

            g.selectAll(".label-group")
                .attr("transform", d => {

                    return `translate(${d.x},${d.y}) scale(${1 / zoom_level})`;
                });

            labelGroups.filter(node => node.id === d.id)
                .style("display", null);


            const connectedNodeIds = new Set(links.flatMap(link =>
                link.source.id === d.id ? [link.target.id] :
                    link.target.id === d.id ? [link.source.id] : []));


            labelGroups.filter(node => connectedNodeIds.has(node.id))
                .style("display", null);


        }).on("mouseout", (event, d) => {
            // Ripristina lo stile originale dei link
            d3.selectAll('.connected-node-tooltip').remove();
            d3.selectAll('.links line')
                .style('stroke-opacity', 0.3)
                .style('stroke', '#999')
                .style('stroke-width', '.5px');
            // labels.style("display", "none");
            labelGroups.style("display", "none");

        });



        function applyCustomForces() {
            nodes.forEach(function (d) {
                const solarData = d.solar ? solarMap.get(d.solar) : null;


                if ((d.group === 'domain' || d.group === 'as') && (d.solar == d.id)) {

                    // Mantieni i nodi 'domain' e 'as' al centro o in posizione fissa
                } else if (solarData) {
                    const target = nodes.find(node => node.id === d.solar);
                    if (target) {

                        const nodeCount = solarData['solar_count']['total'] || 1;


                        const distance = calculateCircleSize(nodeCount, d.group)

                        const angle = Math.PI * 2 * (nodes.indexOf(d) / (nodes.length - 1));
                        d.x += (target.x + Math.cos(angle) * distance - d.x) * 0.1;
                        d.y += (target.y + Math.sin(angle) * distance - d.y) * 0.1;
                    }
                }
            });
        }

        function updateCircles() {
            nodes.forEach(node => {
                const solarData = solarMap.get(node.id);
                // EDIT
                let nodeCount = 1;
                if (solarData) {
                    nodeCount = parseInt(solarData['solar_count'].total);
                }
                else {
                    nodeCount = 1
                }
                let maxRadius = 0;
                if (solarData) {
                    Object.entries(type_distances).forEach(([type, distance]) => {
                        const distanceNew = calculateCircleSize(nodeCount, type)
                        if (solarData['solar_count'][type] > 0) { // Se il tipo di nodo è presente

                            const circleId = `circle-${node.id}-${type}`;
                            let circle = circleGroup.select(`#${circleId}`);
                            if (circle.empty()) {
                                circleGroup.append('circle') // Usa circleGroup per appendere i cerchi
                                    .attr('id', circleId)
                                    .attr('class', 'central-circle')
                                    .attr('r', distanceNew)
                                    //.style('fill', '#2229')
                                    .style('fill', node.group === 'domain' ? '#AED2D622' : '#0D918E33');
                                //.style('stroke', node.group === 'domain' ? 'blue' : 'red');
                            }
                            if (distanceNew > maxRadius) {
                                maxRadius = distanceNew; // Aggiorna il raggio massimo se questo è maggiore

                            }
                            circle
                                .attr('cx', node.x)
                                .attr('cy', node.y);

                        } else {
                            circleGroup.select(`#circle-${node.id}-${type}`).remove(); // Rimuovi il cerchio se il tipo non è presente
                        }
                    });
                    Object.entries(type_distances).forEach(([type, distance]) => {

                        const distanceNew = calculateCircleSize(nodeCount, type)

                        if (solarData['solar_count'][type] > 0) { // Se il tipo di nodo è presente
                            const circleId = `circle2-${node.id}-${type}`;
                            let circle = circleGroup.select(`#${circleId}`);
                            if (circle.empty()) {
                                circleGroup.append('circle') // Usa circleGroup per appendere i cerchi
                                    .attr('id', circleId)
                                    .attr('class', 'central-circle')
                                    .attr('r', distanceNew)
                                    .style('fill', 'none')
                                    .style('stroke', node.group === 'domain' ? '#AED2D6AA' : '#0D918EAA');
                            }
                            if (distanceNew > maxRadius) {
                                maxRadius = distanceNew; // Aggiorna il raggio massimo se questo è maggiore

                            }
                            circle
                                .attr('cx', node.x)
                                .attr('cy', node.y);

                        } else {
                            circleGroup.select(`#circle2-${node.id}-${type}`).remove(); // Rimuovi il cerchio se il tipo non è presente
                        }
                    });
                }

            });
        }

        function calculateCircleSize(totalNode, type = "max") {
            // EDIT
            const r_max = (totalNode / Math.PI) ** (mu_var['mu_circle_r_prop']) //per calcolare un dimensione proporzionale tra solar con pochi nodi e altri con tantissimi
            const r = r_max * type_distances[type] * mu_var['mu_circle_r_x']
            if (r < mu_var['mu_circle_r_min']) {
                return mu_var['mu_circle_r_min']
            }
            return r
        }

        function calculateScale(ratingVal) {
            // Verifica se ratingVal è definito e se è un numero
            if (ratingVal !== undefined && !isNaN(ratingVal)) {
                if (ratingVal === 0) {
                    return 3; // Doppia dimensione per rating 0
                } else {
                    // Calcola un fattore di scala che diminuisce linearmente da 2 a 1 mentre "rating_val" va da 0 a 100
                    return 3 - (ratingVal / 100) * 2;
                }
            } else {
                return 1; // Restituisce il valore di default se ratingVal non è definito o non è un numero
            }
        }

        function chooseColorByGroup(group) {
            const colors = {
                'domain': '#1f77b4',
                'as': '#ff7f0e',
                'AS': '#ff7f0e',
                'email': '#2ca02c',
                'dns': '#d62728', 'host': '#9467bd', 'website': '#8c564b',
                'ip': '#e377c2', 'network': '#7f7f7f'
            };
            return colors[group] || '#17becf';
        }

        function chooseColorByRating(rating) {
            const colors = {
                'BAD_COLOR': '#E7212E',
                'POOR_COLOR': '#F05E24',
                'FAIR_COLOR': '#F6921F',
                'GOOD_COLOR': '#57A6D9',
                'EXCELLENT_COLOR': '#2466B0',
                'NO_RATING': '#888888',
            };
            // EDIT 
            let group = "EXCELLENT_COLOR"
            if (rating == 0) group = "NO_RATING" //domini inseriti automaticamente senza rating
            else if (rating < 30) group = "POOR_COLOR"
            else if (rating < 50) group = "POOR_COLOR"
            else if (rating < 70) group = "FAIR_COLOR"
            else if (rating < 90) group = "GOOD_COLOR"
            else group = "EXCELLENT_COLOR"

            return colors[group];
        }
        function chooseColorByRatingBG(rating) {
            const colors = {
                'BAD_COLOR': '#f0f0f0',
                'POOR_COLOR': '#f0f0f0',
                'FAIR_COLOR': '#f0f0f0',
                'GOOD_COLOR': '#f0f0f0',
                'EXCELLENT_COLOR': '#f0f0f0',
            };
            // EDIT 
            let group = "EXCELLENT_COLOR"
            if (rating == 0) group = "EXCELLENT_COLOR"
            else if (rating < 30) group = "POOR_COLOR"
            else if (rating < 50) group = "POOR_COLOR"
            else if (rating < 70) group = "FAIR_COLOR"
            else if (rating < 90) group = "GOOD_COLOR"
            else group = "EXCELLENT_COLOR"

            return colors[group];
        }
        // up e
        /*
                function ticked() {
                    // Aggiorna la posizione dei link
                    link.attr('x1', d => d.source.x)
                        .attr('y1', d => d.source.y)
                        .attr('x2', d => d.target.x)
                        .attr('y2', d => d.target.y);
        
                    // Aggiorna la posizione dei nodi
                    node.attr('cx', d => d.x)
                        .attr('cy', d => d.y);
                    updateCircles(); // Aggiorna la posizione dei cerchi
                    labels.attr("x", d => d.x).attr("y", d => d.y);
        
                    // Aggiorna o crea le etichette per i nodi 'domain' e 'as'
                    nodes.forEach(d => {
                        if ((d.group === 'domain' && d.id === d.solar) || d.group === 'as') {
                            let label = g.selectAll(`.node-label-${d.id}`)
                                .data([d]);
        
                            label.enter()
                                .append('text')
                                .attr('class', `node-label node-label-${d.id}`) // Utilizza un identificatore unico per ciascuna etichetta
                                .merge(label) // Unisci i nuovi elementi con quelli esistenti
                                .attr('x', d => d.x + 10)
                                .attr('y', d => d.y + 5)
                                .attr('text-anchor', 'left')
                                .style('fill', 'white')
                                .text(`${d.group}: ${d.label}`);
                        }
                    });
                }
        */


        var labels = g.selectAll(".label")
            .data(nodes)
            .enter().append("text")
            .attr("class", "label")
            .attr("dx", 12) // Distanza dall'elemento nodo, adattala a seconda delle tue necessità
            .attr("dy", ".35em") // Allineamento verticale del testo
            .text(d => d.group + ": " + d.label) // Assumi che ciascun nodo abbia una proprietà 'label'
            .style("fill", "#fff") // Colore del testo
            .style("display", "none"); // Nascondi le etichette di default

        /*
        // Aggiungi gestore click ai nodi per evidenziare i link
        node.on("mouseover", (event, d) => {
            // Evidenzia i link diretti
            d3.selectAll(`.link.source-${d.id}, .link.target-${d.id}`)
                .style('stroke-opacity', 1)
                .style('stroke', 'red')
                .style('stroke-width', '2px'); // Rendi i link più spessi per evidenziarli

            labels.filter(node => node.id === d.id)
                .style("display", null);

            // Mostra i tooltip per i nodi collegati
            const connectedNodeIds = new Set(links.flatMap(link =>
                link.source.id === d.id ? [link.target.id] :
                    link.target.id === d.id ? [link.source.id] : []));

            labels.filter(node => connectedNodeIds.has(node.id))
                .style("display", null);


        }).on("mouseout", (event, d) => {
            // Ripristina lo stile originale dei link
            d3.selectAll('.connected-node-tooltip').remove();
            d3.selectAll('.links line')
                .style('stroke-opacity', 0.3)
                .style('stroke', '#999')
                .style('stroke-width', '.5px');
            labels.style("display", "none");
        });
                function applyCustomForces() {
                    nodes.forEach(function (d) {
                        const solarData = d.solar ? solarMap.get(d.solar) : null;
                        //console.log(solarData)
                        //if (d.group === 'domain' || d.group === 'as') {
                        if ((d.group === 'domain' || d.group === 'as') && (d.solar == d.id)) {
                            //console.log("center",d.group,d.label,d.solar,d.id);
                            // Mantieni i nodi 'domain' e 'as' al centro o in posizione fissa
                        } else if (solarData) {
                            const target = nodes.find(node => node.id === d.solar);
                            if (target) {
                                //console.log("Group:",d.group,d.label)
                                const nodeCount = solarData['solar_count']['total'] || 1;
                                //const nodeCount = solarData['solar_count'][d.group] || 1;
                                const baseDistance = distances[d.group] || mu_var.mu_circle_base_distance; //VAR mu_circle_base_distance
                                const distance = baseDistance + nodeCount * mu_var.mu_circle_count; //VAR mu_circle_count
                                const angle = Math.PI * 2 * (nodes.indexOf(d) / (nodes.length - 1));
                                d.x += (target.x + Math.cos(angle) * distance - d.x) * 0.1;
                                d.y += (target.y + Math.sin(angle) * distance - d.y) * 0.1;
                            }
                        }
                    });
                }
        
                function updateCircles() {
                    nodes.forEach(node => {
                        const solarData = solarMap.get(node.id);
                        //console.log(solarData)
                        let nodeCount = 1;
                        if (solarData) {
                            nodeCount = parseInt(solarData['solar_count'].total);
                        }
                        else {
                            nodeCount = 1
        
                        }
        
                        //const nodeCount = solarData['total'] || 0;
                        let maxRadius = 0;
                        if (solarData) {
                            Object.entries(distances).forEach(([type, distance]) => {
                                const distanceNew = distances[type] + nodeCount * mu_var.mu_circle_count; //VAR mu_circle_count
        
        
                                if (solarData['solar_count'][type] > 0) { // Se il tipo di nodo è presente
                                    const circleId = `circle-${node.id}-${type}`;
                                    let circle = circleGroup.select(`#${circleId}`);
                                    if (circle.empty()) {
                                        circleGroup.append('circle') // Usa circleGroup per appendere i cerchi
                                            .attr('id', circleId)
                                            .attr('class', 'central-circle')
                                            .attr('r', distanceNew)
                                            //.style('fill', '#2229')
                                            .style('fill', node.group === 'domain' ? '#AED2D622' : '#0D918E33');
                                        //.style('stroke', node.group === 'domain' ? 'blue' : 'red');
                                    }
                                    if (distanceNew > maxRadius) {
                                        maxRadius = distanceNew; // Aggiorna il raggio massimo se questo è maggiore
                                        //console.log(maxRadius);
                                    }
                                    circle
                                        .attr('cx', node.x)
                                        .attr('cy', node.y);
        
                                } else {
                                    circleGroup.select(`#circle-${node.id}-${type}`).remove(); // Rimuovi il cerchio se il tipo non è presente
                                }
                            });
                            Object.entries(distances).forEach(([type, distance]) => {
                                const distanceNew = distances[type] + nodeCount * mu_var.mu_circle_count; //VAR mu_circle_count
        
        
                                if (solarData['solar_count'][type] > 0) { // Se il tipo di nodo è presente
                                    const circleId = `circle2-${node.id}-${type}`;
                                    let circle = circleGroup.select(`#${circleId}`);
                                    if (circle.empty()) {
                                        circleGroup.append('circle') // Usa circleGroup per appendere i cerchi
                                            .attr('id', circleId)
                                            .attr('class', 'central-circle')
                                            .attr('r', distanceNew)
                                            .style('fill', 'none')
                                            .style('stroke', node.group === 'domain' ? '#AED2D6AA' : '#0D918EAA');
                                    }
                                    if (distanceNew > maxRadius) {
                                        maxRadius = distanceNew; // Aggiorna il raggio massimo se questo è maggiore
                                        //console.log(maxRadius);
                                    }
                                    circle
                                        .attr('cx', node.x)
                                        .attr('cy', node.y);
        
                                } else {
                                    circleGroup.select(`#circle2-${node.id}-${type}`).remove(); // Rimuovi il cerchio se il tipo non è presente
                                }
                            });
                        }
        
                    });
                }
        */
        function chooseColorByGroup(group) {
            const colors = {
                'domain': '#1f77b4', 'as': '#ff7f0e', 'email': '#2ca02c',
                'dns': '#d62728', 'host': '#9467bd', 'website': '#8c564b',
                'ip': '#e377c2', 'network': '#7f7f7f'
            };
            return colors[group] || '#17becf';
        }


        // Define the function to handle resizing
        function handleResize() {
            // const width = svg.node().parentElement.clientWidth;

            const width = document.getElementById("take_width_size_solar").offsetWidth;
            const height = Math.min(window.innerHeight, width);

            setDimensions({ width, height });
            // Adjust scaling
            svg.attr("width", width);
            svg.attr("height", height);
        }

        // fullscreen s
        var fullscreenEnabled = !document.location.hash.match(/^#nofullscreen/);

        if (fullscreenEnabled) {
            setTimeout(function () {
                const button = document.createElement("button");
                document.getElementById("sol-viz").parentNode.appendChild(button);
                button.setAttribute("id", "fullscreen");
                button.setAttribute("class", "z-[999]");
                button.setAttribute("style", "cursor:pointer;");
                button.innerHTML = "Fullscreen";
                button.addEventListener("click", goFullscreen);
            }, 200);
        }

        function goFullscreen() {
            this._exitFired = false;
            if (fullScreenApi.supportsFullScreen) {
                if (fullScreenApi.isFullScreen(document.getElementById("sol-viz"))) {
                    fullScreenApi.cancelFullScreen(document.getElementById("sol-viz"));
                } else {
                    fullScreenApi.requestFullScreen(document.getElementById("sol-viz"));
                }
                setTimeout(redraw, 200);
            }
        }

        /*
        Native FullScreen JavaScript API
        -------------
        Assumes Mozilla naming conventions instead of W3C for now
        
        source : http://johndyer.name/native-fullscreen-javascript-api-plus-jquery-plugin/
        
        */

        (function () {
            var fullScreenApi = {
                supportsFullScreen: false,
                isFullScreen: function () {
                    return false;
                },
                requestFullScreen: function () { },
                cancelFullScreen: function () { },
                fullScreenEventName: "",
                prefix: ""
            },
                browserPrefixes = "webkit moz o ms khtml".split(" ");

            // check for native support
            if (typeof document.cancelFullScreen != "undefined") {
                fullScreenApi.supportsFullScreen = true;
            } else {
                // check for fullscreen support by vendor prefix
                for (var i = 0, il = browserPrefixes.length; i < il; i++) {
                    fullScreenApi.prefix = browserPrefixes[i];
                    if (
                        typeof document[fullScreenApi.prefix + "CancelFullScreen"] !=
                        "undefined"
                    ) {
                        fullScreenApi.supportsFullScreen = true;

                        break;
                    }
                }
            }

            // update methods to do something useful
            if (fullScreenApi.supportsFullScreen) {
                fullScreenApi.fullScreenEventName =
                    fullScreenApi.prefix + "fullscreenchange";

                fullScreenApi.isFullScreen = function () {
                    switch (this.prefix) {
                        case "":
                            return document.fullScreen;
                        case "webkit":
                            return document.webkitIsFullScreen;
                        default:
                            return document[this.prefix + "FullScreen"];
                    }
                };
                fullScreenApi.requestFullScreen = function (el) {
                    return this.prefix === ""
                        ? el.requestFullScreen()
                        : el[this.prefix + "RequestFullScreen"]();
                };
                fullScreenApi.cancelFullScreen = function (el) {
                    return this.prefix === ""
                        ? document.cancelFullScreen()
                        : document[this.prefix + "CancelFullScreen"]();
                };

                document.addEventListener(fullScreenApi.fullScreenEventName, function () {
                    if (fullScreenApi.isFullScreen(document.body)) {
                        document.getElementById("fullscreen").innerHTML = "&times;";
                    } else {
                        document.getElementById("fullscreen").innerHTML = "Fullscreen";
                    }
                });
            }

            // export api
            window.fullScreenApi = fullScreenApi;
        })();

        // fullscreen e

        // Attach an event listener to the window to handle resizing
        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener("resize", handleResize);

    }, []);

    return (
        <div className={`App${darkMode && darkMode === true ? " dark" : null} min-h-[560px]`} id="sol-viz">
            <div id="take_width_size_solar" style={{ width: "100%" }} />

            <svg ref={svgRef} width={dimensions.width} height={dimensions.height} />
            <div id="controlButtons" className="z-[999] relative flex gap-2 px-4" style={{ marginBottom: -20 }}>
                <MDButton onClick={() => solarFunction ? solarFunction.showDefaultCenterLabels() : null} style={cysrConfig().button_style} size="medium" id="showSolarLabels">Inner label</MDButton>
                {
                    // uguale a default
                    false && <MDButton onClick={() => solarFunction ? solarFunction.showSolarLabels() : null} style={cysrConfig().button_style} size="medium" id="showSolarLabels">Show Stars(? Labels) </MDButton>}
                <MDButton onClick={() => solarFunction ? solarFunction.showLowRatingLabels() : null} style={cysrConfig().button_style} size="medium" id="showLowRatingLabels">Low Rating</MDButton>
                <MDButton onClick={() => solarFunction ? solarFunction.hideAllLabels() : null} style={cysrConfig().button_style} size="medium" id="hideAllLabels">Hide all</MDButton>
            </div>
            <p className='z-[998] pb-6 text-sm relative text-center text-turquoise-800 dark:text-turquoise-200'>
                Hold the Ctrl key to interact with the graph
            </p>
        </div>
    );
}
/* eslint-disable */
// prettier-ignore