/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
import { Base64 } from 'js-base64';
import { useState, useEffect, useRef } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import DefaultStatisticsCard from "layouts/cysr/overview/DefaultStatisticsCard";
import { Link, useLocation } from "react-router-dom";
import loadingUrl from "layouts/cysr/images/loading.svg";
// import DashboardCompanyInfo from "layouts/cysr/components/DashboardCompanyInfo";

// import HierarchicalGraph from "layouts/cysr/components/HierarchicalGraph";
import AttackPerimeterGraph from "layouts/cysr/components/CircleGraph";

import ForceGraph from "layouts/cysr/components/GraphForce";
import MDSnackbar from "components/MDSnackbar";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
// import Icon from '@mui/material/Icon';
import ScoreItem from "layouts/cysr/components/ScoreItem";
import MDBadgeDot from "components/MDBadgeDot";

import GraphGeneral from "layouts/cysr/components/GraphGeneral";
import Loader from "layouts/cysr/components/Loader";
import StatusItem from "layouts/cysr/components/StatusItem";
import InfoButton from "layouts/cysr/components/infoButton";
import Divider from "@mui/material/Divider";

import { ImportantMessage } from "layouts/cysr/components/ImportantMessage";
// import CompanyDetail from "layouts/cysr/CompanyDetail";

// import Icon from "@mui/material/Icon";
// looks bad:
// import RadarChart from "examples/Charts/RadarChart";
import { AlluvialChart, LineChart, RadarChart } from "@carbon/charts-react";
import "@carbon/charts/styles-g100.css";
import cysrConfig from "layouts/cysr/config";

import "./App.css"

// import { CYSRCompanyRadar } from "@/app/company/components/radar";
import { CYSRCompanyRadar } from "layouts/cysr/components/radar";
// import { Timeline } from "@/app/company/components/timeline";
import { Timeline } from "layouts/cysr/components/timeline";
import ChartSolar from "../components/ChartSolar";


// https://www.robinwieruch.de/react-component-to-pdf/
// import html2canvas from 'html2canvas';
// import { jsPDF } from 'jspdf';

/*
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});
*/

// Material Dashboard 2 React Examples
/* eslint-disable */
function Sales() {
  const printRefRadar = useRef();
  const printRefTimeline = useRef();
  const [isLoading, setLoading] = useState(true);
  const btnDownloadReportDefaultString = "Download report as PDF";
  const [btnDownloadReportString, setStringBtnDownloadReport] = useState(btnDownloadReportDefaultString);
  const location = useLocation();
  const [forceGraphDialogShow, setForceGraphDialogShow] = useState(false);
  // Our State.
  const [views, setViews] = useState([]);
  const accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  // DefaultStatisticsCard state for the dropdown value
  const [salesDropdownValue, setSalesDropdownValue] = useState("6 May - 7 May");

  // DefaultStatisticsCard state for the dropdown action
  const [salesDropdown, setSalesDropdown] = useState(null);

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({ currentTarget }) => setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({ currentTarget }) => {
    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
  };

  const handleDownloadPdf = async () => {
    setStringBtnDownloadReport('Generating report...')
    fetch(`${cysrConfig().api_base_url}dashboard/account/${accountUUID}/report/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
    })

      .then(res => res.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        /*
        window.location.assign(file);
        */
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'CYSR-report.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setStringBtnDownloadReport(btnDownloadReportDefaultString)
      });
    /*
      .then((response) => {
        const doc = await response.blob()
        response.blob()
        console.log(response)
      })
        .then((response) => response.json())
        .then((reportResponse) => {
          console.log("report data");
          console.log(reportResponse)
        })
        */
  };
  /*
  const handleDownloadPdf = async (v) => {
    const elementRadar = printRefRadar.current;
    const elementTimeline = printRefTimeline.current;
    //edit element s

    //edit element e
    const canvasRadar = await html2canvas(elementRadar, {
      //allowTaint: true,
      //useCORS: true
    });
    const canvasTimeline = await html2canvas(elementTimeline, {
      //allowTaint: true,
      //useCORS: true
    });
    const dataRadar = canvasRadar.toDataURL('image/png');
    const dataTimeline = canvasTimeline.toDataURL('image/png');

    const pdf = new jsPDF({
      format: 'a4',
      orientation: 'p',
      putOnlyUsedFonts:true,
      unit: 'mm',
      compress: true
    });
    const imgPropertiesRadar = pdf.getImageProperties(dataRadar);
    const imgPropertiesTimeline = pdf.getImageProperties(dataTimeline);
    const imgMargins = 16;
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeightRadar =
      (imgPropertiesRadar.height * pdfWidth) / imgPropertiesRadar.width;
    const pdfHeightTimeline =
      (imgPropertiesTimeline.height * pdfWidth) / imgPropertiesTimeline.width;
    pdf.addFont("Helvetica","Ubuntu","normal",400,undefined,true)
    pdf.addFont("Helvetica-Bold","Ubuntu","normal",700)
    // console.log(v)
    let mmFromTop = 8;
    let mmFromLeft = 8;
    let titleHeight = 12;
    let ratingImageHeight = 80;
    let ratingImageWidth = ratingImageHeight * 0.5988;
    const chartsVerticalDistance = 88;
    let mmFromLeftCompanyProfile = parseInt(ratingImageWidth, 10) + 16;
    const dividedDimensionRadar = 2.25;
    pdf.addImage(dataRadar, 'SVG', mmFromLeft, ratingImageHeight + titleHeight + 16, pdfWidth / dividedDimensionRadar, pdfHeightRadar / dividedDimensionRadar);
    const dividedDimensionTimeline = 1.5;
    pdf.addImage(dataTimeline, 'SVG', mmFromLeft + 32, ratingImageHeight + titleHeight + 16 + chartsVerticalDistance, pdfWidth / dividedDimensionTimeline, pdfHeightTimeline / dividedDimensionTimeline);
    pdf.setFillColor(0,0,0);
    pdf.rect(mmFromLeft,mmFromTop + titleHeight, ratingImageWidth, ratingImageHeight, 'F');
    pdf.setTextColor(0, 0, 0);
    pdf.setFontSize(14)
    pdf.text(mmFromLeft, 16, 'CYSR Report');
    pdf.setFontSize(12)
    pdf.text(mmFromLeftCompanyProfile, 24, `${v.accounts_role.company_profile.account_name}`);
    pdf.setFontSize(8)
    pdf.text(mmFromLeftCompanyProfile, 28, `${v.accounts_role.company_profile.account_vat_name}`);
    pdf.setFontSize(8)
    pdf.text(mmFromLeftCompanyProfile, 32, `${v.accounts_role.company_profile.account_vat_address}`);
    pdf.setFontSize(8)
    pdf.text(mmFromLeftCompanyProfile, 36, `${v.accounts_role.company_profile.account_vat_city}`);
    pdf.setFontSize(8)
    pdf.text(mmFromLeftCompanyProfile, 40, "VAT:");
    pdf.setFontSize(8)
    pdf.text(mmFromLeftCompanyProfile + 8, 40, `${v.accounts_role.company_profile.account_data_national_id}`);

    let mmFromLeftAssetsSummarized = mmFromLeftCompanyProfile + 64;
    pdf.setFontSize(12)
    pdf.text(mmFromLeftAssetsSummarized, 24, "Assets");
    pdf.setFontSize(18)
    pdf.text(mmFromLeftAssetsSummarized, 32, `${v.assets.summarized.asset_count_total}`);

    pdf.setFontSize(12)
    pdf.setTextColor("#000000");
    pdf.setFont("Ubuntu","normal",400)
    pdf.text(mmFromLeftAssetsSummarized + 8, 32, "(");

    pdf.setFontSize(12)
    pdf.setTextColor("#0D918F");
    pdf.setFont("Ubuntu","normal",700)
    pdf.text(mmFromLeftAssetsSummarized + 10, 32, `${v.assets.summarized.rating_completion_rate_total}%`);

    pdf.setFontSize(12)
    pdf.setTextColor("#000000");
    pdf.setFont("Ubuntu","normal",400)
    pdf.text(mmFromLeftAssetsSummarized + 20, 32, "analysed)");
    
    //by status
    var yDistance = 40;
    for (const key in v.assetsByStatus) {
      if (Object.hasOwnProperty.call(v.assetsByStatus, key)) {
        const element = v.assetsByStatus[key];

        pdf.setFontSize(10)
        pdf.setTextColor("#000000");
        pdf.setFont("Ubuntu","normal",700)
        pdf.text(mmFromLeftAssetsSummarized, yDistance, `${element}`);

        pdf.setFontSize(8)
        pdf.setTextColor("#000000");
        pdf.setFont("Ubuntu","normal",400)
        pdf.text(mmFromLeftAssetsSummarized + 10, yDistance, `${element > 1 ? "are" : "is"}`);

        pdf.setFontSize(8)
        pdf.setTextColor(`${key === "ENABLED" ? "#4CAF50" : (key === "DISABLED" ? "#F44335" : "#FB8C00")}`);
        pdf.setFont("Ubuntu","normal",700)
        pdf.text(mmFromLeftAssetsSummarized + 20, yDistance, `${key[0].toUpperCase() + key.slice(1).toLowerCase()}`);


        yDistance = yDistance+6
        
      }
    }
    //by source
    //manual
    pdf.setFontSize(10)
    pdf.setTextColor("#000000");
    pdf.setFont("Ubuntu","normal",700)
    pdf.text(mmFromLeftAssetsSummarized, yDistance + 4, `${v.assetsManualNumber}`);

    pdf.setFontSize(8)
    pdf.setTextColor("#000000");
    pdf.setFont("Ubuntu","normal",400)
    pdf.text(mmFromLeftAssetsSummarized + 10, yDistance + 4, "in");

    pdf.setFontSize(8)
    pdf.setTextColor("#000000");
    pdf.setFont("Ubuntu","normal",700)
    pdf.text(mmFromLeftAssetsSummarized + 20, yDistance + 4, "Manual");
    //checker auto-discovery
    pdf.setFontSize(10)
    pdf.setTextColor("#000000");
    pdf.setFont("Ubuntu","normal",700)
    pdf.text(mmFromLeftAssetsSummarized, yDistance + 12, `${v.assetsCheckerNumber}`);

    pdf.setFontSize(8)
    pdf.setTextColor("#000000");
    pdf.setFont("Ubuntu","normal",400)
    pdf.text(mmFromLeftAssetsSummarized + 10, yDistance + 12, "in");

    pdf.setFontSize(8)
    pdf.setTextColor("#000000");
    pdf.setFont("Ubuntu","normal",700)
    pdf.text(mmFromLeftAssetsSummarized + 20, yDistance + 12, "Auto-discovery");

    //assets.summarized.rating_completion_rate_by_type s
    yDistance = ratingImageHeight + titleHeight + 16
    const mmFromLeftAssetsByType = mmFromLeftAssetsSummarized;
    for (const key in v.assetsByType) {
      if (Object.hasOwnProperty.call(v.assetsByType, key)) {
        const element = v.assetsByType[key];

        pdf.setFontSize(10)
        pdf.setTextColor("#000000");
        pdf.setFont("Ubuntu","normal",700)
        pdf.text(mmFromLeftAssetsByType, yDistance, `${element}`);

        pdf.setFontSize(8)
        pdf.setTextColor("#000000");
        pdf.setFont("Ubuntu","normal",400)
        pdf.text(mmFromLeftAssetsByType + 10, yDistance, `${element > 1 ? "are" : "is"}`);

        pdf.setFontSize(8)
        pdf.setTextColor("#000000");
        pdf.setFont("Ubuntu","normal",700)
        pdf.text(mmFromLeftAssetsByType + 20, yDistance, `${key[0].toUpperCase() + key.slice(1).toLowerCase()}`);

        pdf.setFontSize(8)
        pdf.setTextColor("#000000");
        pdf.setFont("Ubuntu","normal",400)
        pdf.text(mmFromLeftAssetsByType + 20 + 16, yDistance, "(");

        pdf.setFontSize(8)
        pdf.setTextColor("#0D918F");
        pdf.setFont("Ubuntu","normal",700)
        pdf.text(mmFromLeftAssetsByType + 20 + 17, yDistance, `${v.assets.summarized.rating_completion_rate_by_type[key]}%`);

        pdf.setFontSize(8)
        pdf.setTextColor("#000000");
        pdf.setFont("Ubuntu","normal",400)
        pdf.text(mmFromLeftAssetsByType + 20 + 26, yDistance, "analysed)");


        yDistance = yDistance+6
        
      }
    }
    //assets.summarized.rating_completion_rate_by_type e

    /*
    //add rating img s
    var img = new Image;
    img.onload = function() {
        pdf.addImage(this, 10, 10);
        //pdf.addSvgAsImage(this, 10, 10);
        // pdf.save("CTStest.pdf");
        pdf.save('CYSR_Report.pdf');
        };
    //img.crossOrigin = "";
    img.crossOrigin = "anonymous";
    img.src = imgRatingSrc.replace(".svg", ".png");
    //add rating img e
    *//*
pdf.save('CYSR_Report.pdf');
};
*/

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Last 30 days</MenuItem>
    </Menu>
  );
  let ratingVal = 0;
  let ratingImage = loadingUrl;
  // const dashboardCallUrl = `${cysrConfig().api_base_url}dashboard/account/${accountUUID}/`;
  const dashboardCallUrl = `${cysrConfig().api_base_url}dashboard/mainpage/${accountUUID}/`;
  useEffect(() => {
    setLoading(true);
    fetch(dashboardCallUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
      },
    })
      .then((response) => response.json())
      .then((responseDashboardAccountData) => {
        // console.log("mainpage data");
        // console.log(responseDashboardAccountData)

        // set values of boxes
        if (
          responseDashboardAccountData &&
          responseDashboardAccountData.rating_actual &&
          responseDashboardAccountData.rating_actual.rating_val &&
          responseDashboardAccountData.rating_actual.rating_val != null
        ) {
          ratingVal = responseDashboardAccountData.rating_actual.rating_val;
          ratingImage = `${cysrConfig().image_rating_base_url}${ratingVal}.svg`;
        } else {
          ratingImage = `${cysrConfig().image_rating_base_url}no-rating.svg`;
        }
        const nAssetsNumber = responseDashboardAccountData.assets?.summarized?.asset_count_total || 0;
        let nDomainsNumber = null
        let nIpsNumber = null
        let nEmailsNumber = null
        let nApplicationsNumber = null
        let nHostsNumber = null
        let nOthersNumber = null

        let nAssetsManualNumber = null
        let nAssetsCheckerNumber = null
        let nAssetsAutoNumber = null
        if (responseDashboardAccountData.assets?.summarized?.asset_count_by_type) {

          nDomainsNumber = responseDashboardAccountData.assets?.summarized?.asset_count_by_type.domain || 0;
          nIpsNumber = responseDashboardAccountData.assets?.summarized?.asset_count_by_type.ip || 0;
          nEmailsNumber = responseDashboardAccountData.assets?.summarized?.asset_count_by_type.email || 0;
          nApplicationsNumber = responseDashboardAccountData.assets?.summarized?.asset_count_by_type.app || 0;
          nHostsNumber = responseDashboardAccountData.assets?.summarized?.asset_count_by_type.host || 0;
          nOthersNumber = responseDashboardAccountData.assets?.summarized?.asset_count_by_type.others || 0;
        }
        if (responseDashboardAccountData.assets?.summarized?.asset_count_by_source) {
          nAssetsManualNumber = responseDashboardAccountData.assets?.summarized?.asset_count_by_source.manual || 0;
          nAssetsCheckerNumber = responseDashboardAccountData.assets?.summarized?.asset_count_by_source.checker || 0;
          nAssetsAutoNumber = responseDashboardAccountData.assets?.summarized?.asset_count_by_source.auto || 0;
        }
        // fix error for alluvial
        const alluvialData = responseDashboardAccountData.assets.graphs.alluvial;
        const alluvialDataNodesFixed = [];
        /*
        // fix flows (remove value 0)
        alluvialData.flows.forEach(function(f){
          if (f.value < 1) {
            f.value = 0.1;
          }
        });
        */
        // fix nodes (remove senza corrispondenza)
        if (alluvialData) {
          alluvialData.nodes.forEach(function (v) {
            // se ci sono dei nodi non presenti in data avviene un errore, rimuovo i nodi senza corrispondenze:
            let found = false;
            alluvialData.flows.forEach(f => {
              if (f.source === v.name || f.target === v.name) {
                // if(f.source === v.name || f.target === v.name){
                found = true;
              }
            });
            if (found === true && (alluvialDataNodesFixed.indexOf(v) > -1) === false) {
              alluvialDataNodesFixed.push(v);
              /*
              // push senza rating_val
              alluvialDataNodesFixed.push({
                category: v.category,
                name: v.name
              });
              */
            }
          });
          alluvialData.nodes = alluvialDataNodesFixed;
        }
        // console.log(alluvialData);

        let assetsByTypeOrdered = [];
        let assetsByTypeKeys = (responseDashboardAccountData.assets?.summarized && responseDashboardAccountData.assets?.summarized?.asset_count_by_type) ? Object.keys(responseDashboardAccountData.assets?.summarized?.asset_count_by_type) : [];
        function getSortRank(t) {
          if (t == 'domain') {
            return 10
          } else if (t == 'email') {
            return 20
          } else if (t == 'dns') {
            return 30
          } else if (t == 'host') {
            return 40
          } else if (t == 'website') {
            return 50
          } else if (t == 'ip') {
            return 60
          } else if (t == 'network') {
            return 70
          } else if (t == 'as') {
            return 80
          } else {
            return 0
          }
        }
        assetsByTypeKeys.sort((a, b) => {
          return getSortRank(a) - getSortRank(b)
        })
        assetsByTypeKeys.forEach(e => {
          assetsByTypeOrdered.push({
            label: e,
            value: responseDashboardAccountData.assets?.summarized?.asset_count_by_type[e]
          })
        });
        let orderedCircle2 = {};
        if (responseDashboardAccountData.assets.graphs?.circle2) {
          orderedCircle2.tree = (responseDashboardAccountData.assets.graphs.circle2.tree).sort((a, b) => {
            return getSortRank(a.group) - getSortRank(b.group)
          });
          orderedCircle2.flowEdges = responseDashboardAccountData.assets.graphs.circle2.flowEdges;
          // add status to circle graph s
          responseDashboardAccountData.assets.graphs?.general?.nodes.forEach(node => {
            orderedCircle2.tree.find((x) => x.id === node.id).status = node.status
          });
          // add status to circle graph e
        }
        setViews({
          accounts_role: responseDashboardAccountData,
          assets: responseDashboardAccountData.assets,
          rating: ratingVal,
          ratingImageSrc: ratingImage,
          assetsByStatus: responseDashboardAccountData.assets?.summarized?.asset_count_by_status,
          assetsByType: assetsByTypeOrdered,
          ratingCompletionRateTotal: responseDashboardAccountData.assets?.summarized?.rating_completion_rate_total,
          ratingCompletionRateByType: responseDashboardAccountData.assets?.summarized?.rating_completion_rate_by_type,
          // probably not used s:
          domainsNumber: nDomainsNumber,
          ipsNumber: nIpsNumber,
          emailsNumber: nEmailsNumber,
          applicationsNumber: nApplicationsNumber,
          hostsNumber: nHostsNumber,
          assetsNumber: nAssetsNumber,
          assetsManualNumber: nAssetsManualNumber,
          assetsCheckerNumber: nAssetsCheckerNumber && nAssetsAutoNumber ? (nAssetsCheckerNumber + nAssetsAutoNumber) : null,
          othersNumber: nOthersNumber,
          // probably not used e
          // graphs
          // alluvial: responseDashboardAccountData.assets.graphs.alluvial,
          alluvial: alluvialData,
          general: responseDashboardAccountData.assets.graphs?.general,
          rating_count_by_type_and_groupval: responseDashboardAccountData.assets?.summarized?.rating_count_by_type_and_groupval,
          circle: responseDashboardAccountData.assets.graphs.circle,
          circle2: orderedCircle2,
          radar: responseDashboardAccountData.assets.graphs.radar,
          radarIndustryAverage: responseDashboardAccountData.assets.graphs.radar_industry_average,
          timeline: responseDashboardAccountData.assets.graphs.timeline,
          // sub preview s
          isSubscriptionPreview: responseDashboardAccountData.company_profile.account_has_starter_subscription,
          // sub preview e
          risk_actual: responseDashboardAccountData.risk_actual
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
      });
  }, [location]);
  return (
    <DashboardLayout>
      {
        // sub preview s
        // <DashboardNavbar cysr_company />
      }
      {isLoading === false && views ? <DashboardNavbar cysr_company={views.isSubscriptionPreview ?
        // "Essential/Advanced/Preview"
        "Starter"
        : true} risk_actual={views && views.risk_actual ? views.risk_actual : false} /> : null}
      {
        // sub preview e
      }
      <Grid container spacing={3}>
        {isLoading ? (
          <Grid item xs={12}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={2}>
                  <Loader />
                </Grid>
                <Grid item container spacing={3} xs={12} md={8} lg={10}>
                  <Grid item container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <Loader />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Loader />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Loader />
                      <Grid item container spacing={3}>
                        <Grid item xs={6}>
                          <Loader />
                        </Grid>
                        <Grid item xs={6}>
                          <Loader />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={3}>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                      <Loader />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                      <Loader />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                      <Loader />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                      <Loader />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                      <Loader />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        ) : (
          <Grid item container spacing={3}>
            <Grid item xs={12} lg={views.isSubscriptionPreview ? 5 : 4}>
              <Card style={{ height: "100%" }}>
                <InfoButton cysr_info={cysrConfig().i("overview", "assets", "help")} cysr_info_link={cysrConfig().i("overview", "assets", "help_link")} />
                <MDBox py={5} px={3} fullWidth>
                  {cysrConfig().i("overview", "assets", "title") && (
                    <MDTypography variant="h5">{cysrConfig().i("overview", "assets", "title")}</MDTypography>
                  )}
                  {cysrConfig().i("overview", "assets", "sub_title") && (
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {cysrConfig().i("overview", "assets", "sub_title")}
                    </MDTypography>
                  )}

                  {views.isSubscriptionPreview ? <MDBox display="flex" flexDirection="row" alignItems="center">
                    <MDBox display="flex" alignItems="center">
                      <MDTypography mr={1} style={{ fontSize: "1.5rem", fontFamily: "Ubuntu" }} variant="h6">{views.assetsNumber || 0}</MDTypography>
                    </MDBox>
                    <div>
                      <MDTypography variant="overline" style={{ fontSize: "1.125rem" }} color="text">(</MDTypography>
                      <MDTypography variant="overline" style={{ fontSize: "1.125rem" }} color="info"><strong>{`${String(views.ratingCompletionRateTotal || 0)}%`}</strong></MDTypography>
                      <MDTypography variant="overline" style={{ fontSize: "1.125rem" }} color="text">&nbsp;analysed)</MDTypography>
                    </div>
                  </MDBox> : <Link to={`/company/${accountUUID}/${"assets"}`}>
                    <MDBox display="flex" flexDirection="row" alignItems="center">
                      <MDBox display="flex" alignItems="center">
                        <MDTypography mr={1} style={{ fontSize: "1.5rem", fontFamily: "Ubuntu" }} variant="h6">{views.assetsNumber || 0}</MDTypography>
                      </MDBox>
                      <div>
                        <MDTypography variant="overline" style={{ fontSize: "1.125rem" }} color="text">(</MDTypography>
                        <MDTypography variant="overline" style={{ fontSize: "1.125rem" }} color="info"><strong>{`${String(views.ratingCompletionRateTotal || 0)}%`}</strong></MDTypography>
                        <MDTypography variant="overline" style={{ fontSize: "1.125rem" }} color="text">&nbsp;analysed)</MDTypography>
                      </div>
                    </MDBox>
                  </Link>}
                  {
                    // sub preview s
                    views.isSubscriptionPreview ? null : <>

                      {views && views.assetsByStatus && Object.keys(views.assetsByStatus).map((t) => {
                        return (
                          <>
                            <Divider />
                            <MDBox display="flex" alignItems="center" color="text" key={t} >
                              <MDTypography mr={1} style={{ minWidth: "28px", fontFamily: "Ubuntu Mono" }} variant="h6">{views.assetsByStatus[t] || 0}</MDTypography>
                              <MDTypography mr={1} style={{ width: "18px" }} variant="caption">{views.assetsByStatus[t] > 1 ? "are" : "is"}</MDTypography>
                              <StatusItem statusValue={t} withLabel withText />
                            </MDBox>
                          </>
                        )
                      })}
                      <Divider />
                      <MDBox display="flex" flexDirection="column">
                        {
                          // <Link to={`/company/${accountUUID}/${"assets"}#manual`}>
                        }
                        <MDBox display="flex" alignItems="center">
                          <MDTypography mr={1} style={{ minWidth: "28px", fontFamily: "Ubuntu Mono" }} variant="h6">{views.assetsManualNumber || 0}</MDTypography>
                          <MDTypography mr={1} style={{ width: "18px" }} variant="caption">in</MDTypography>
                          <MDTypography mr={1} variant="caption"><strong>Manual</strong></MDTypography>
                        </MDBox>
                        {
                          /*
                          </Link>
                          <Link to={`/company/${accountUUID}/${"assets"}#checker`}>
                          */
                        }
                        <MDBox display="flex" alignItems="center">
                          <MDTypography mr={1} style={{ minWidth: "28px", fontFamily: "Ubuntu Mono" }} variant="h6">{views.assetsCheckerNumber || 0}</MDTypography>
                          <MDTypography mr={1} style={{ width: "18px" }} variant="caption">in</MDTypography>
                          <MDTypography mr={1} variant="caption"><strong>Auto-discovery</strong></MDTypography>
                        </MDBox>
                        {
                          // </Link>
                        }
                      </MDBox>
                    </>
                  }
                </MDBox>
                {
                  // metto radar qui per starter s
                  views.isSubscriptionPreview ? views && views.radar && views.radar.length > 0 && (
                    <div className="cysr-box-shadowless ml-2" style={{ position: "relative" }}>
                      <InfoButton cysr_info={cysrConfig().i("overview", "chart_radar", "help")} cysr_info_link={cysrConfig().i("overview", "chart_radar", "help_link")} />
                      <MDBox py={5} px={0} fullWidth>
                        {cysrConfig().i("overview", "chart_radar", "title") && (
                          <MDTypography variant="h5">{cysrConfig().i("overview", "chart_radar", "title")}</MDTypography>
                        )}
                        {cysrConfig().i("overview", "chart_radar", "sub_title") && (
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {cysrConfig().i("overview", "chart_radar", "sub_title")}
                          </MDTypography>
                        )}
                        <CYSRCompanyRadar data={views.radar} radarIndustryAverage={views.radarIndustryAverage} darkMode={localStorage.getItem("cysr_dark_mode") != null ? localStorage.getItem("cysr_dark_mode") === "true" : true} />
                        {false &&
                          <MDBox ref={printRefRadar} style={{
                            /*
                            // starting red, changing to blue, and finishing green
                            // background: "radial-gradient(circle at center, red 0, blue, green 100%)"
                            // background: "radial-gradient(circle at center center, hsla(356, 82%, 51%, 0.8) 0, hsla(30, 92%, 54%, 0.8), hsla(51, 82%, 54%, 0.8), hsla(147, 86%, 34%, 0.8), hsla(206, 73%, 58%, 0.8) 100px, transparent 100%)"
                            background: `radial-gradient(circle at center center, 
                              hsla(356, 82%, 51%, 0.8) 12%, 
                              hsla(30, 92%, 54%, 0.8) 23.5%, 
                              hsla(51, 82%, 54%, 0.8) 33%, 
                              hsla(147, 86%, 34%, 0.8) 42%, 
                              hsla(206, 73%, 58%, 0.8) 47%, 
                              hsla(206, 73%, 58%, 0.8) 47%, 
                              transparent 47%)`,
                            paddingRight: "20px"
                            */
                          }}> <RadarChart
                              data={views.radar}
                              options={{
                                "title": false,
                                "radar": {
                                  "axes": {
                                    "angle": "type",
                                    "value": "rating",
                                  },
                                  "alignment": "center"
                                },
                                "data": {
                                  "groupMapsTo": "label"
                                },
                                "legend": {
                                  "enabled": false,
                                },
                                "height": "400px",
                                "toolbar": { "enabled": false },
                              }}
                            />
                          </MDBox>
                        }
                      </MDBox>
                    </div>) : null
                  // metto radar qui per starter e
                }
              </Card>
            </Grid>
            {views && views.assetsByType &&
              <Grid item container sm={12} lg={views.isSubscriptionPreview ? 7 : 8} spacing={1.5}>
                {views && views.assetsByType && views.assetsByType.map((t) => {
                  return (
                    <Grid item key={t.label} xs={12} md={6} lg={views.isSubscriptionPreview ? 4 : 3}>
                      <div style={{ position: "relative" }}>
                        <InfoButton cysr_info={cysrConfig().i("overview", t.label, "help")} cysr_info_link={cysrConfig().i("overview", t.label, "help_link")} />
                      </div>
                      {views.isSubscriptionPreview ? <DefaultStatisticsCard
                        title={t.label}
                        count={`${t.value || 0}`}
                        percentage={{
                          color: "info",
                          value: `${views.ratingCompletionRateByType[t.label]}%`,
                          label: "analysed",
                        }}
                        warning={Math.floor(Math.random() * 10)}
                        ratings={views.rating_count_by_type_and_groupval[t.label]}
                      /> :
                        <Link to={`/company/${accountUUID}/${"assets"}?t=${Base64.encode('["type:' + t.label + '"]')}`}>
                          <DefaultStatisticsCard
                            title={t.label}
                            count={`${t.value || 0}`}
                            percentage={{
                              color: "info",
                              value: `${views.ratingCompletionRateByType[t.label]}%`,
                              label: "analysed",
                            }}
                            warning={Math.floor(Math.random() * 10)}
                            // ratings={views.general.nodes}
                            ratings={views.rating_count_by_type_and_groupval[t.label]}
                          /*
                          dropdown={{
                            action: openSalesDropdown,
                            menu: renderMenu(salesDropdown, closeSalesDropdown),
                            value: salesDropdownValue,
                          }}
                          */
                          />
                        </Link>
                      }
                    </Grid>
                  )
                })}
              </Grid>
            }
            <Grid item xs={12}>
              <Card>
                <InfoButton cysr_info={cysrConfig().i("overview", "charts", "help")} cysr_info_link={cysrConfig().i("overview", "charts", "help_link")} />
                <MDBox py={0} px={3} fullWidth>
                  {cysrConfig().i("overview", "charts", "title") && (
                    <MDTypography variant="h5">{cysrConfig().i("overview", "charts", "title")}</MDTypography>
                  )}
                  {cysrConfig().i("overview", "charts", "sub_title") && (
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {cysrConfig().i("overview", "charts", "sub_title")}
                    </MDTypography>
                  )}
                  {views.isSubscriptionPreview ? null : <Grid container spacing={3}
                  // className="cysr_charts"
                  >
                    {views && views.radar && views.radar.length > 0 && (
                      <Grid item md={12} lg={5}>
                        <div className="cysr-box-shadowless" style={{ position: "relative" }}>
                          <InfoButton cysr_info={cysrConfig().i("overview", "chart_radar", "help")} cysr_info_link={cysrConfig().i("overview", "chart_radar", "help_link")} />
                          <MDBox py={5} px={0} fullWidth>
                            {cysrConfig().i("overview", "chart_radar", "title") && (
                              <MDTypography variant="h5">{cysrConfig().i("overview", "chart_radar", "title")}</MDTypography>
                            )}
                            {cysrConfig().i("overview", "chart_radar", "sub_title") && (
                              <MDTypography variant="button" color="text" fontWeight="regular">
                                {cysrConfig().i("overview", "chart_radar", "sub_title")}
                              </MDTypography>
                            )}
                            <CYSRCompanyRadar data={views.radar} radarIndustryAverage={views.radarIndustryAverage} darkMode={localStorage.getItem("cysr_dark_mode") != null ? localStorage.getItem("cysr_dark_mode") === "true" : true} />
                            {false &&
                              <MDBox ref={printRefRadar} style={{
                                /*
                                // starting red, changing to blue, and finishing green
                                // background: "radial-gradient(circle at center, red 0, blue, green 100%)"
                                // background: "radial-gradient(circle at center center, hsla(356, 82%, 51%, 0.8) 0, hsla(30, 92%, 54%, 0.8), hsla(51, 82%, 54%, 0.8), hsla(147, 86%, 34%, 0.8), hsla(206, 73%, 58%, 0.8) 100px, transparent 100%)"
                                background: `radial-gradient(circle at center center, 
                                  hsla(356, 82%, 51%, 0.8) 12%, 
                                  hsla(30, 92%, 54%, 0.8) 23.5%, 
                                  hsla(51, 82%, 54%, 0.8) 33%, 
                                  hsla(147, 86%, 34%, 0.8) 42%, 
                                  hsla(206, 73%, 58%, 0.8) 47%, 
                                  hsla(206, 73%, 58%, 0.8) 47%, 
                                  transparent 47%)`,
                                paddingRight: "20px"
                                */
                              }}> <RadarChart
                                  data={views.radar}
                                  options={{
                                    "title": false,
                                    "radar": {
                                      "axes": {
                                        "angle": "type",
                                        "value": "rating",
                                      },
                                      "alignment": "center"
                                    },
                                    "data": {
                                      "groupMapsTo": "label"
                                    },
                                    "legend": {
                                      "enabled": false,
                                    },
                                    "height": "400px",
                                    "toolbar": { "enabled": false },
                                  }}
                                />
                              </MDBox>
                            }
                          </MDBox>
                        </div>

                      </Grid>)}
                    {
                      <Grid item md={12} lg={7}>
                        {views && views.timeline && views.timeline.length > 0 && (
                          <div className="cysr-box-shadowless" style={{ position: "relative" }}>
                            <InfoButton cysr_info={cysrConfig().i("overview", "chart_timeline", "help")} cysr_info_link={cysrConfig().i("overview", "chart_timeline", "help_link")} />
                            <MDBox py={5} px={0} fullWidth>
                              {cysrConfig().i("overview", "chart_timeline", "title") && (
                                <MDTypography variant="h5">{cysrConfig().i("overview", "chart_timeline", "title")}</MDTypography>
                              )}
                              {cysrConfig().i("overview", "chart_timeline", "sub_title") && (
                                <MDTypography variant="button" color="text" fontWeight="regular">
                                  {cysrConfig().i("overview", "chart_timeline", "sub_title")}
                                </MDTypography>
                              )}
                            </MDBox>
                            <div style={{
                              /*
                              overflowX: "auto",
                              // so it start at the end of overflow:
                              display: "flex",
                              flexDirection: "row-reverse"
                              */
                              width: "100%",
                            }}>
                              <Timeline
                                // data={company.assets.graphs.timeline} 
                                data={views.timeline}
                              />
                            </div>
                            {false && <MDBox ref={printRefTimeline}>
                              <LineChart
                                data={views.timeline}
                                options={{
                                  "title": false,
                                  "axes": {
                                    "left": {
                                      "mapsTo": "value",
                                      "domain": [
                                        0,
                                        100
                                      ]
                                    },
                                    "bottom": {
                                      "scaleType": "time"
                                    }
                                  },
                                  "timeScale": {
                                    "timeIntervalFormats": {
                                      "hourly": {
                                        "primary": "MMM d, HH:mm",
                                        "secondary": "HH:mm"
                                      }
                                    }
                                  },
                                  "legend": {
                                    "enabled": false,
                                  },
                                  "height": "400px",
                                  "toolbar": { "enabled": false }
                                }}
                              />
                            </MDBox>}
                          </div>
                        )}
                      </Grid>
                    }
                  </Grid>
                  }
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        )}
        {
          /*
          views && (
              <CompanyDetail data={views} />
          )
          */
        }
        {
          views.isSubscriptionPreview
            ? null
            /*
            <Grid item xs={12}>
              <a href="#" style={{
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
                display: "block",
                width: "100%"
              }}>
                <ImportantMessage
                  button={"Upgrade subscription"}
                  className={`text-left w-full border pl-11 pr-4 py-3 rounded border-cyan-500/50 bg-cyan-500/5`}
                  title={
                    "Your subscription is in preview."
                  }
                  description="Get all the fancy stuffs by upgrading."
                />
              </a>
              </Grid>
              */
            : isLoading ? (
              <Grid item xs={12}>
                <MDBox>
                  <Loader />
                </MDBox>
              </Grid>
            ) : (
              <Grid item container spacing={3}>
                {false && (
                  // hide alluvial
                  <MDBox my={1}>
                    <Card p={1} style={{ marginTop: "1em" }}>
                      {views && views.alluvial && views.alluvial.nodes && views.alluvial.nodes.length > 0 ? (
                        <AlluvialChart
                          data={views.alluvial.flows}
                          options={{
                            "title": false,
                            "alluvial": {
                              "nodes": views.alluvial.nodes,
                              "nodeAlignment": "left"
                            },
                            "height": "400px",
                            "toolbar": {
                              "enabled": false
                            }
                          }}
                        />
                      ) : (
                        <MDTypography variant="button" color="text">
                          No alluvial graph available
                        </MDTypography>
                      )}
                    </Card>
                  </MDBox>
                )}
                {views && views.circle2 && views.circle2.tree && views.circle2.tree.length > 0 && (
                  <Grid item xs={12}>
                    <Card>
                      <InfoButton cysr_info={cysrConfig().i("overview", "chart_circle", "help")} cysr_info_link={cysrConfig().i("overview", "chart_circle", "help_link")} />
                      <MDBox py={5} px={3} fullWidth>
                        {cysrConfig().i("overview", "chart_circle", "title") && (
                          <MDTypography variant="h5">{cysrConfig().i("overview", "chart_circle", "title")}</MDTypography>
                        )}
                        {cysrConfig().i("overview", "chart_circle", "sub_title") && (
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {cysrConfig().i("overview", "chart_circle", "sub_title")}
                          </MDTypography>
                        )}
                        <AttackPerimeterGraph data={views.circle2} darkMode={localStorage.getItem("cysr_dark_mode") != null ? localStorage.getItem("cysr_dark_mode") === "true" : true} />
                      </MDBox>
                    </Card>
                  </Grid>
                )}
                {false &&
                  <Grid item xs={12}>
                    <Card>
                      <InfoButton cysr_info={cysrConfig().i("overview", "chart_general", "help")} cysr_info_link={cysrConfig().i("overview", "chart_general", "help_link")} />
                      <MDBox py={5} px={3} fullWidth>
                        {cysrConfig().i("overview", "chart_general", "title") && (
                          <MDTypography variant="h5">{cysrConfig().i("overview", "chart_general", "title")}</MDTypography>
                        )}
                        {cysrConfig().i("overview", "chart_general", "sub_title") && (
                          <MDTypography variant="button" color="text" fontWeight="regular">
                            {cysrConfig().i("overview", "chart_general", "sub_title")}
                          </MDTypography>
                        )}
                        {views.general && views.general.nodes && views.general.nodes.length > 0
                          ? <>
                            <ForceGraph dataGraphRaw={views.general} darkMode={localStorage.getItem("cysr_dark_mode") != null ? localStorage.getItem("cysr_dark_mode") === "true" : true} />
                          </>
                          : <MDTypography variant="button" color="text">
                            No force graph available
                          </MDTypography>
                        }
                      </MDBox>
                    </Card>
                  </Grid>
                }
                {true && <Grid item xs={12}>
                  <Card>
                    {views.general && views.general.nodes && views.general.nodes.length > 0
                      ? <>
                        <ChartSolar dataGraphRaw={views.general} darkMode={localStorage.getItem("cysr_dark_mode") != null ? localStorage.getItem("cysr_dark_mode") === "true" : true} />
                      </>
                      : <MDTypography variant="button" color="text">
                        No force graph available
                      </MDTypography>
                    }
                  </Card>
                </Grid>
                }
                <Grid item xs={12}>
                  <Card>
                    <InfoButton cysr_info={cysrConfig().i("overview", "chart_general", "help")} cysr_info_link={cysrConfig().i("overview", "chart_general", "help_link")} />
                    <MDBox py={5} px={3} fullWidth>
                      {views.general && views.general.nodes && views.general.nodes.length > 0 ?
                        // se views.assetsNumber > 200 non mostrare
                        views.assetsNumber && views.assetsNumber > 0 ?
                          <a href={`/company/${accountUUID}/chart-general`} target="_blank" style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            textAlign: "center",
                            display: "block",
                            width: "100%"
                          }}>
                            <ImportantMessage
                              button={"View chart in new tab"}
                              className={`text-left w-full border pl-11 pr-4 py-3 rounded border-cyan-500/50 bg-cyan-500/5`}
                              title={
                                "View Asset Diagram"
                              }
                              description="To avoid slow rendering this chart opens in a new tab"
                            />

                          </a>
                          : (
                            <GraphGeneral data={views.general} />
                          ) : (
                          <MDTypography variant="button" color="text">
                            No general graph available
                          </MDTypography>
                        )}
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            )}
      </Grid>
    </DashboardLayout>
  );
}
/* eslint-disable */
export default Sales;
