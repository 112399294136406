/* eslint-disable */
// prettier-ignore

import { useState, useEffect } from "react";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardNavbar from "layouts/cysr/components/Navbars/DashboardNavbar";
import { cn } from "lib/utils"

import { ToggleGroup, ToggleGroupItem } from "layouts/cysr/insight/ToggleGroup";
import Card from "@mui/material/Card";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import { Button } from "layouts/cysr/components/ui/button";

import { toast } from "sonner"
import CysrDropdown from "layouts/cysr/components/CysrDropdown";
import { useMaterialUIController } from "context";

import {
  Card as CardIn,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "layouts/cysr/components/ui/card";


import cysrConfig, { process } from "layouts/cysr/config";

import { triggerAction } from "../components/buttonActions";

import { Separator } from "layouts/cysr/components/ui/separator";
import MDSnackbar from "components/MDSnackbar";

import { useSnackbar } from 'notistack';
import MDButton from "components/MDButton";
import { TimeshiftCalendarDatePicker } from "layouts/cysr/reports/timeshift-date-picker";
import { lightFormat, startOfToday } from "date-fns";

function Reports() {
  //snackBar s
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // add action to an individual snackbar
  const action = snackbarId => (
    <>
      <MDButton onClick={() => {
        window.location.reload()
        closeSnackbar(snackbarId)
      }} style={cysrConfig().button_style} size="medium">
        <span style={cysrConfig().button_icon_left_style} dangerouslySetInnerHTML={{ __html: cysrConfig().close.icon.svg }} />
        Dismiss and reload page
      </MDButton>
    </>
  );
  //snackBar e

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const accountUUID = window.location.pathname.substring(
    window.location.pathname.indexOf("/company/") + "/company/".length,
    window.location.pathname.lastIndexOf("/")
  );
  const [showCalendar, setShowCalendar] = useState(false);
  // mdsnack s
  const [show, setShow] = useState(false);
  const [msgDateTime, setMsgDateTime] = useState("Information");
  const [msgIcon, setMsgIcon] = useState("info");
  const [msgTheme, setMsgTheme] = useState("light");
  const [msgTitle, setMsgTitle] = useState("Recheck not executed");
  const [msgContent, setMsgContent] = useState(
    <></>
  )

  const [timeshift, setTimeshift] = useState(null);

  const defaultToggleSnackbar = () => {
    setShow(!show);
  }
  function toggleSnackbar(title = "Error", msg = "Error", theme = "error", icon = "error", dateTime = "Error") {
    setMsgDateTime(dateTime);
    setMsgIcon(icon);
    setMsgTheme(theme);
    setMsgTitle(title);
    setMsgContent(msg);
    setShow(true);
  }
  // mdsnack e

  function selectCallback(key) {
    alert(key)
  }

  // Our State.
  const [views, setViews] = useState([]);
  // Tutti gli asset di un account (/asset/) senza UUID?
  if (localStorage.getItem("cysr_useraccountroles") === null) {
    window.location.href = "/cysr/login";
  }
  const [reportStandardLanguage, setReportStandardLanguage] = useState(false);
  const [reportStandardLanguageARCHIVED, setReportStandardLanguageARCHIVED] = useState(false);
  const [reportCTILanguage, setReportCTILanguage] = useState(false);
  const [reportRiskLanguage, setReportRiskLanguage] = useState(false);
  const [reportFullLanguage, setReportFullLanguage] = useState(false);
  const [reportByTagsLanguage, setReportByTagsLanguage] = useState(false);
  const [showSelectTags, setShowSelectTags] = useState(false);
  const [filters, setFilters] = useState([]);


  const getCallUrl = `${cysrConfig().api_base_url}dashboard/pageheader/${accountUUID}/`;
  useEffect(() => {
    const pageTitle = "Reports";
    document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;
    if (localStorage.getItem("cysr_useraccountroles")) {
      fetch(getCallUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
        },
      })
        .then((response) => response.json())
        .then(async (callResponse) => {
          let reportAvailableOptions = [];
          let reportStandard = {
            label: process.env.NEXT_PUBLIC_STRING_REPORT_A,
            value: "Generate report",
            key: 0,
            options: []
          }
          let reportByTags = {
            label: process.env.NEXT_PUBLIC_STRING_REPORT_T,
            value: "Generate report by Tags",
            key: 10,
            options: []
          }
          let reportCTI = {
            label: process.env.NEXT_PUBLIC_STRING_REPORT_C,
            value: "Generate CTI report",
            key: 50,
            options: []
          }
          let reportRisk = {
            label: process.env.NEXT_PUBLIC_STRING_REPORT_R,
            value: "Generate risk report",
            key: 500,
            options: []
          }
          let reportFull = {
            label: process.env.NEXT_PUBLIC_STRING_REPORT_F,
            value: "Generate full report",
            key: 10,
            options: []
          }
          if (await cysrConfig().feature_available("company_report_en")) {
            // standard report en
            reportStandard.options.push({
              label: "English",
              color: "info",
              value: "Generate report",
              onClick: "go_to_report",
              key: 0,
              // icon: cysrConfig().report.icon.svg
            }
            );
            //show all languages
            //}
            //if (cysrConfig().feature_available("company_report_it")) {
            // standard report it
            reportStandard.options.push({
              label: "Italiano",
              color: "info",
              value: "Generate report (IT)",
              onClick: "go_to_report_it",
              key: 20,
              // icon: cysrConfig().report.icon.svg
            }
            );
            //show all languages
            //}
            //if (cysrConfig().feature_available("company_report_de")) {
            // standard report de
            reportStandard.options.push(
              {
                label: "Deutsch",
                color: "info",
                value: "Generate report (DE)",
                onClick: "go_to_report_de",
                key: 21,
                // icon: cysrConfig().report.icon.svg
              }
            );
            //show all languages
            //}
            //if (cysrConfig().feature_available("company_report_es")) {
            // standard report es
            reportStandard.options.push(
              {
                label: "Español",
                color: "info",
                value: "Generate report (ES)",
                onClick: "go_to_report_es",
                key: 23,
                // icon: cysrConfig().report.icon.svg
              }
            );
            //show all languages
            //}
            //if (cysrConfig().feature_available("company_report_fr")) {
            // standard report fr
            reportStandard.options.push(
              {
                label: "Français",
                color: "info",
                value: "Generate report (FR)",
                onClick: "go_to_report_fr",
                key: 26,
                // icon: cysrConfig().report.icon.svg
              }
            );
            //}
            // closes check on en_report:

            // report by tags s
            // ByTags report en
            reportByTags.options.push({
              label: "English",
              color: "info",
              value: "Generate report",
              onClick: "go_to_report_by_tags",
              key: 0,
              // icon: cysrConfig().report.icon.svg
            }
            );
            //show all languages
            //}
            //if (cysrConfig().feature_available("company_report_it")) {
            // ByTags report it
            reportByTags.options.push({
              label: "Italiano",
              color: "info",
              value: "Generate report (IT)",
              onClick: "go_to_report_by_tags_it",
              key: 20,
              // icon: cysrConfig().report.icon.svg
            }
            );
            //show all languages
            //}
            //if (cysrConfig().feature_available("company_report_de")) {
            // ByTags report de
            reportByTags.options.push(
              {
                label: "Deutsch",
                color: "info",
                value: "Generate report (DE)",
                onClick: "go_to_report_by_tags_de",
                key: 21,
                // icon: cysrConfig().report.icon.svg
              }
            );
            //show all languages
            //}
            //if (cysrConfig().feature_available("company_report_es")) {
            // ByTags report es
            reportByTags.options.push(
              {
                label: "Español",
                color: "info",
                value: "Generate report (ES)",
                onClick: "go_to_report_by_tags_es",
                key: 23,
                // icon: cysrConfig().report.icon.svg
              }
            );
            //show all languages
            //}
            //if (cysrConfig().feature_available("company_report_fr")) {
            // ByTags report fr
            reportByTags.options.push(
              {
                label: "Français",
                color: "info",
                value: "Generate report (FR)",
                onClick: "go_to_report_by_tags_fr",
                key: 26,
                // icon: cysrConfig().report.icon.svg
              }
            );
            //}
            // closes check on en_report:
            // report by tags e
          }
          if (
            callResponse.risk_actual &&
            (Array.isArray(callResponse.risk_actual) && callResponse.risk_actual.length === 0) === false &&
            Object.keys(callResponse.risk_actual).length !== 0 && // Check if not an empty object
            await cysrConfig().feature_available("company_risk_report")
          ) {
            reportRisk.options.push(
              {
                label: "English",
                color: "info",
                value: "Generate risk report",
                onClick: "go_to_report_risk",
                key: 800,
                // icon: cysrConfig().report.icon.svg
              }
            );
            [
              {
                label: "Italiano",
                code: "it"
              },
              {
                label: "Deutsch",
                code: "de"
              },
              {
                label: "Español",
                code: "es"
              },
              {
                label: "Français",
                code: "fr"
              }
            ].map((l, i) => {
              reportRisk.options.push(
                {
                  label: l.label,
                  color: "info",
                  value: `Generate risk report (${l.code.toUpperCase()})`,
                  onClick: `go_to_report_risk_${l.code}`,
                  key: 810 + i * 20,
                  // icon: cysrConfig().report.icon.svg,
                }
              );
            })
          }
          if (await cysrConfig().feature_available("company_cti_report")) {
            reportCTI.options.push(
              {
                label: "English",
                color: "info",
                value: "Generate CTI report",
                onClick: "go_to_report_cti",
                key: 300,
                // icon: cysrConfig().report.icon.svg
              }
            );
            [
              {
                label: "Italiano",
                code: "it"
              },
              {
                label: "Deutsch",
                code: "de"
              },
              {
                label: "Español",
                code: "es"
              },
              {
                label: "Français",
                code: "fr"
              }
            ].map((l, i) => {
              reportCTI.options.push(
                {
                  label: l.label,
                  color: "info",
                  value: `Generate CTI report (${l.code.toUpperCase()})`,
                  onClick: `go_to_report_cti_${l.code}`,
                  key: 150 + i * 20,
                  // icon: cysrConfig().report.icon.svg,
                }
              );
            })

            // full, qui perchè contiene il CTI s

            // full report en
            reportFull.options.push(
              {
                label: "English",
                color: "info",
                value: "Generate full report",
                onClick: "go_to_report_full",
                key: 5,
                // icon: cysrConfig().report.icon.svg,

              }
            );
            // full report languages
            [
              {
                label: "Italiano",
                code: "it"
              },
              {
                label: "Deutsch",
                code: "de"
              },
              {
                label: "Español",
                code: "es"
              },
              {
                label: "Français",
                code: "fr"
              }
            ].map((l, i) => {
              reportFull.options.push(
                {
                  label: l.label,
                  color: "info",
                  value: `Generate full report (${l.code.toUpperCase()})`,
                  onClick: `go_to_report_full_${l.code}`,
                  key: 600 + i * 100,
                  // icon: cysrConfig().report.icon.svg,
                }
              );
            })
            // full, qui perchè contiene il CTI e

          }
          if (reportStandard.options.length > 0) {
            reportAvailableOptions.push(reportStandard)
            setReportStandardLanguage(reportStandard.options[0].label)
          }
          if (reportCTI.options.length > 0) {
            reportAvailableOptions.push(reportCTI)
            setReportCTILanguage(reportCTI.options[0].label)
          }
          if (reportFull.options.length > 0) {
            reportAvailableOptions.push(reportFull)
            setReportFullLanguage(reportFull.options[0].label)
          }
          if (reportRisk.options.length > 0) {
            reportAvailableOptions.push(reportRisk)
            setReportRiskLanguage(reportRisk.options[0].label)
          }
          if (reportByTags.options.length > 0) {
            reportAvailableOptions.push(reportByTags)
            setReportByTagsLanguage(reportByTags.options[0].label)
          }
          // archived s
          if (reportStandard.options.length > 0) {
            // spread syntax to create a shallow copy
            //const copiedObject = { ...originalObject };
            const reportStandardARCHIVED = { ...reportStandard };
            reportStandardARCHIVED.archived = true;
            reportStandardARCHIVED.label = `${process.env.NEXT_PUBLIC_STRING_REPORT_A_ARCHIVED}`;

            // options s
            let repArchOptions = [
              {
                label: "English",
                code: "en"
              },
              {
                label: "Italiano",
                code: "it"
              },
              {
                label: "Deutsch",
                code: "de"
              },
              /*
              {
                label: "Español",
                code: "es"
              },
              */
              {
                label: "Français",
                code: "fr"
              }
            ].map((l, i) => (
              {
                label: l.label,
                color: "info",
                value: l.code === "en" ?
                  `${process.env.NEXT_PUBLIC_STRING_REPORT_A_ARCHIVED}` :
                  `${process.env.NEXT_PUBLIC_STRING_REPORT_A_ARCHIVED} (${l.code.toUpperCase()})`,
                onClick: l.code === "en" ? `go_to_report_ARCHIVED` : `go_to_report_${l.code}_ARCHIVED`,
                key: 3600 + i * 100,
                // icon: cysrConfig().report.icon.svg,
              }
            )
            )
            reportStandardARCHIVED.options = repArchOptions;
            // options e

            reportStandardARCHIVED.onClick = reportStandardARCHIVED.onClick + '_ARCHIVED';
            // hide now s
            // reportAvailableOptions.push(reportStandardARCHIVED)
            // hide now e
            setReportStandardLanguageARCHIVED(reportStandardARCHIVED.options[0].label)
          }
          // setReportsTimeshiftData s
          const getReportsTimeshiftCallUrl = `${cysrConfig().api_base_url}timeshift/${accountUUID}/dates/`;
          fetch(getReportsTimeshiftCallUrl, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
            },
          })
            .then((responseReportsTimeshift) => responseReportsTimeshift.json())
            .then((callResponseReportsTimeshift) => {
              // archived e
              setViews({
                reportOptions: reportAvailableOptions,
                cover: callResponse.subscription && callResponse.subscription.report_cover ? callResponse.subscription.report_cover : "https://cysr.com/static/images/cover.jpg",
                reportsTimeshiftData: callResponseReportsTimeshift
              });
            })
          // setReportsTimeshiftData e
        })
        .catch((err) => console.error(err));
    } else {
      setViews({});
    }
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar cysr_company />
      <>
        <div className={`${darkMode ? "dark " : ""}w-full`}>
          <div className="flex flex-col w-full gap-4 mb-4">
            <CardIn className="w-full rounded-md">
              <CardHeader>
                <CardTitle>Reports</CardTitle>
                <CardDescription>
                  Here you can download your reports. You can also choose a previous checked date and the language you prefer.
                </CardDescription>
                {views && views.reportsTimeshiftData
                  ? <div className="p-2 rounded-md" style={
                    {
                      background: darkMode ? "#121619" : "#f0f2f5"
                    }
                  }>
                    <div className="flex justify-between gap-2 items-center">
                      <div className="flex gap-1 items-center">
                        <Button
                          variant={"outline"}
                          size="sm"
                          onClick={() => setShowCalendar(!showCalendar)}
                          className={cn(
                            "text-black dark:text-white w-[240px] flex items-center justify-start text-left font-normal",
                            `${lightFormat(
                              timeshift && timeshift.report_date ? new Date(timeshift.report_date) : startOfToday()
                              , process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT) !==
                              lightFormat(
                                startOfToday(),
                                process.env.NEXT_PUBLIC_DATE_FORMAT_TIMESHIFT
                              )
                              // ? "focus-visible:ring-teal-400  dark:ring-offset-teal-950 dark:focus-visible:ring-teal-800 border-teal-200 bg-teal-50 hover:bg-teal-100 hover:text-teal-900 dark:border-teal-800 dark:bg-teal-950 dark:hover:bg-teal-800 dark:hover:text-teal-50"
                              ? "bg-white dark:bg-[rgb(34,39,42)]"
                              : null
                            }`,
                            !(timeshift && timeshift.report_date ? true : false)
                            && "text-muted-foreground"
                          )}
                        >
                          {showCalendar ? <svg
                            className="mr-2 h-4 w-4"
                            width="16"
                            height="16"
                            fill="currentColor"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="CalendarMonthSharpIcon"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z"></path>
                          </svg>
                            : <svg
                              className="mr-2 h-4 w-4"
                              width="16"
                              height="16"
                              fill="currentColor"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24" data-testid="EditCalendarSharpIcon"
                              xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 22H3V4h3V2h2v2h8V2h2v2h3v8h-2v-2H5v10h7v2zm10.13-5.01 1.41-1.41-2.12-2.12-1.41 1.41 2.12 2.12zm-.71.71-5.3 5.3H14v-2.12l5.3-5.3 2.12 2.12z"></path>
                            </svg>
                          }
                          {timeshift && timeshift.report_date ? lightFormat(new Date(timeshift.report_date), process.env.NEXT_PUBLIC_DATE_FORMAT) : <span>Pick a date (default: today)</span>}
                        </Button>
                        {timeshift && timeshift.report_date
                          ? <Button onClick={() => {
                            setTimeshift(null)
                            toggleSnackbar(`${process.env.NEXT_PUBLIC_STRING_CONTENT_TIMESHIFT_TODAY_TITLE}`, `${process.env.NEXT_PUBLIC_STRING_CONTENT_TIMESHIFT_TODAY}`, "info", "info", "Info")
                          }} size="sm" variant="ghost"
                          // className="text-black dark:text-white flex items-center justify-start text-left font-normal"
                          >
                            <svg
                              className="mr-2 h-4 w-4"
                              width="16"
                              height="16"
                              fill="currentColor"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24" data-testid="TodaySharpIcon"
                              xmlns="http://www.w3.org/2000/svg">
                              <path d="M21 3h-3V1h-2v2H8V1H6v2H3v18h18V3zm-2 16H5V8h14v11zM7 10h5v5H7v-5z"></path>
                            </svg>Use today</Button> : null}
                      </div>
                      <MDButton style={{
                        //borderRadius: ".375rem",
                        maxHeight: "32px",
                        minHeight: "32px",
                        // minWidth: "32px",
                        padding: 0,
                        // flex: 1
                      }} size="medium"

                        onClick={() => setShowCalendar(!showCalendar)}
                        className="muscope-cta"
                      >
                        <span className="sr-only">Toggle calendar</span>
                        <svg fill="currentColor" className="inline h-4 w-4" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ExpandMoreSharpIcon">
                          {showCalendar
                            ? <path d="m12 8-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"></path>
                            : <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                          }
                        </svg>
                      </MDButton>
                    </div>
                    {showCalendar
                      ? <TimeshiftCalendarDatePicker
                        callback={(timeshift) => {
                          timeshift
                            ? toggleSnackbar(`${process.env.NEXT_PUBLIC_STRING_CONTENT_TIMESHIFT_TITLE}`, `${process.env.NEXT_PUBLIC_STRING_CONTENT_TIMESHIFT} ${timeshift.report_date ? "date: " + timeshift.report_date : ""} ${timeshift.report_version ? "version: " + timeshift.report_version : ""}`, "success", "info", "Info") // setAssets(getAssetsListFromReport(timeshift.account_assets))
                            : toggleSnackbar(`${process.env.NEXT_PUBLIC_STRING_CONTENT_TIMESHIFT_TODAY_TITLE}`, `${process.env.NEXT_PUBLIC_STRING_CONTENT_TIMESHIFT_TODAY}`, "info", "info", "Info") // setAssets(tempAssets);
                        }}
                        timeshiftData={views.reportsTimeshiftData}
                        setTimeshift={setTimeshift}
                      />
                      : null
                    }
                  </div>
                  : <span>Loading calendar</span>
                }
              </CardHeader>
              <h2 className="px-6 pb-6 font-bold text-lg">
                {
                  timeshift && timeshift.report_date
                    ? `Your previous reports as of ${lightFormat(new Date(timeshift.report_date), process.env.NEXT_PUBLIC_DATE_FORMAT)} are:`
                    : `Your last reports are:`
                }
              </h2>
              <CardContent>

                {views && views.reportOptions && (
                  <div className="grid gap-9 md:grid-cols-2 xl:grid-cols-3"
                    style={{
                      // maxWidth: 1280
                      maxWidth: 1024
                    }}>
                    {views.reportOptions.map((r) => {
                      return (
                        <>
                          {r.archived ?
                            <div style={{
                              gridColumn: '1 / -1'
                            }}>
                              <Separator className="my-4" />
                              <CardTitle>Old Layout Reports</CardTitle>
                              <CardDescription>
                                Here you can find old layout reports
                              </CardDescription>
                            </div> : null}


                          <div className="rounded-2xl shadow-lg ring-1 ring-gray-900/5" style={
                            {
                              // background: `${darkMode ? "#05555E" : "#aed2d5"}`,
                              background: `${darkMode
                                ? r.archived
                                  ? "linear-gradient(60deg, rgba(250,250,250,.2), #27383a)"
                                  : "linear-gradient(60deg, rgba(250,250,250,.2), #05555E)"
                                : r.archived
                                  ? "linear-gradient(60deg, rgba(152, 180, 178, .3),#bac8c9)"
                                  : "linear-gradient(60deg, rgba(152, 180, 178, .3),#aed2d5)"}`,
                              border: `1px solid rgb(${darkMode
                                ? "39, 39, 42"
                                : "244, 244, 248"
                                })`,
                              color: `${darkMode ? "#fff" : "#000"}`,
                              // aspectRatio: "210 / 297",
                            }
                          }>
                            <div className="flex flex-col gap-4 px-5 py-3">
                              <h3 className="text-lg font-medium">{r.label}</h3>
                            </div>
                            <img src={views.cover} alt="Report cover" className="aspect-square h-auto w-full object-cover"
                              style={
                                r.archived ? {
                                  filter: "grayscale(.75)"
                                } : null
                              }
                            />

                            <div className="flex flex-wrap md:flex-nowrap items-center justify-between gap-2 px-5 py-3">
                              <CysrDropdown data={
                                {
                                  asset: null,
                                  isDisabled: false,
                                  isLoading: false,
                                  options: r.options,
                                  value: r.options[0],
                                  defaultValue:
                                    r.options[0],
                                  onChange: (x) => {
                                    const o = (r.options).find((l) => {
                                      return l.value === x
                                    })
                                    if (o && o.label) {
                                      const language = o.label

                                      if (r.label === process.env.NEXT_PUBLIC_STRING_REPORT_A) {
                                        // classic/standard report
                                        setReportStandardLanguage(language)
                                      } else if (r.label === process.env.NEXT_PUBLIC_STRING_REPORT_T) {
                                        // report by tags
                                        setReportByTagsLanguage(language)
                                      } else if (r.label === process.env.NEXT_PUBLIC_STRING_REPORT_C) {
                                        // cti report
                                        setReportCTILanguage(language)
                                      } else if (r.label === process.env.NEXT_PUBLIC_STRING_REPORT_R) {
                                        // risk report
                                        setReportRiskLanguage(language)
                                      } else if (r.label === process.env.NEXT_PUBLIC_STRING_REPORT_F) {
                                        // full report
                                        setReportFullLanguage(language)
                                      }
                                      else if (r.label === process.env.NEXT_PUBLIC_STRING_REPORT_A_ARCHIVED) {
                                        // ARCHIVED classic/standard report
                                        setReportStandardLanguageARCHIVED(language)
                                      }

                                    } else {
                                      console.error("404")
                                      console.error(x)
                                      console.error(r)
                                    }
                                  }
                                }
                              }
                              />
                              {
                                r.label === process.env.NEXT_PUBLIC_STRING_REPORT_T
                                  ?
                                  // /account/ID/assets/tags/
                                  <MDButton style={{
                                    //borderRadius: ".375rem",
                                    minHeight: "32px",
                                    minWidth: "32px",
                                    padding: 0,
                                    flex: 1
                                  }} size="medium" onClick={
                                    () => {
                                      fetch(`${cysrConfig().api_base_url}account/${accountUUID}/assets/tags/`, {
                                        headers: {
                                          Authorization: `Bearer ${localStorage.getItem("cysr_access")}`,
                                        },
                                      })
                                        .then((response) => response.json())
                                        .then(async (callResponse) => {
                                          console.warn("callResponse")
                                          console.warn(callResponse)
                                          setShowSelectTags(callResponse)
                                        })
                                        .catch((err) => console.error(err));
                                    }
                                  }
                                    className="muscope-cta"
                                  // className="muscope-cta !text-white"
                                  // className="muscope-cta inline-flex items-center gap-2 rounded-full font-medium"
                                  // className="muscope-cta inline-flex items-center gap-4 rounded-full px-5 py-2.5 text-base font-medium"
                                  >
                                    <span style={{
                                      fontWeight: 500,
                                      textTransform: "none"
                                    }}>Choose tags</span>
                                  </MDButton>

                                  :
                                  <MDButton style={{
                                    //borderRadius: ".375rem",
                                    minHeight: "32px",
                                    minWidth: "32px",
                                    padding: 0,
                                    flex: 1
                                  }} size="medium" onClick={() => {
                                    const selectedOption = (r.options).find((o) => {
                                      let comparedL = false;

                                      if (r.label === process.env.NEXT_PUBLIC_STRING_REPORT_A) {
                                        // classic/standard report
                                        comparedL = reportStandardLanguage;
                                      } else if (r.label === process.env.NEXT_PUBLIC_STRING_REPORT_T) {
                                        // report by tags
                                        comparedL = reportByTagsLanguage;
                                      } else if (r.label === process.env.NEXT_PUBLIC_STRING_REPORT_C) {
                                        // cti report
                                        comparedL = reportCTILanguage;
                                      } else if (r.label === process.env.NEXT_PUBLIC_STRING_REPORT_R) {
                                        // risk report
                                        comparedL = reportRiskLanguage;
                                      } else if (r.label === process.env.NEXT_PUBLIC_STRING_REPORT_F) {
                                        // full report
                                        comparedL = reportFullLanguage;
                                      } else if (r.label === process.env.NEXT_PUBLIC_STRING_REPORT_A_ARCHIVED) {
                                        // archived report
                                        comparedL = reportStandardLanguageARCHIVED;
                                      }

                                      return o.label === comparedL;
                                    })
                                    if (selectedOption) {
                                      triggerAction(selectedOption.onClick, timeshift)
                                    } else {
                                      toast.error("Not a valid option.")
                                    }
                                  }}
                                    className="muscope-cta"
                                  // className="muscope-cta !text-white"
                                  // className="muscope-cta inline-flex items-center gap-2 rounded-full font-medium"
                                  // className="muscope-cta inline-flex items-center gap-4 rounded-full px-5 py-2.5 text-base font-medium"
                                  >
                                    <span className="sr-only">Download</span>
                                    <svg fill="currentColor" className="inline h-4 w-4" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FileDownloadSharpIcon">
                                      <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
                                    </svg>
                                  </MDButton>
                              }
                            </div>
                          </div>
                        </>)
                    })}
                  </div >)}


              </CardContent>
            </CardIn>

            {showSelectTags &&
              <>
                <div className="cysr-tags-setup">
                  <Card>
                    <MDBox p={3}>
                      <MDTypography
                        style={{ width: "100%", textAlign: "right", cursor: "pointer", margin: "4px auto" }}
                        variant="h5"
                        color="text"
                        lineHeight={1}
                        onClick={() => {
                          setShowSelectTags(false)
                        }}
                      >
                        <Icon color="text">close</Icon>
                      </MDTypography>
                      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                        <MDTypography variant="h5" fontWeight="medium">Select tags.</MDTypography>
                        {views && showSelectTags && showSelectTags.length > 0
                          ?
                          <ToggleGroup className="justify-start mt-2" type="multiple" onValueChange={(v) => {
                            setFilters(v)
                          }}>
                            {
                              showSelectTags.map(
                                (x) => x
                                  ? <ToggleGroupItem className="inline-flex gap-2 items-center" value={x}>
                                    <MDTypography
                                      style={{ width: "100%", textAlign: "center", cursor: "pointer", margin: "4px auto" }}
                                      variant="body1"
                                      //color="info"
                                      lineHeight={1}
                                    >
                                      <Icon color="inherit">tags</Icon>
                                      {x}
                                    </MDTypography>
                                  </ToggleGroupItem>
                                  : "no tags available"
                              )}
                          </ToggleGroup>
                          : "no tags available"}
                        <MDTypography variant="button" mb={1}>Download the report with the selected tags.</MDTypography>
                        {
                          // report by tags download cta s
                        }
                        <MDButton style={{
                          //borderRadius: ".375rem",
                          minHeight: "32px",
                          minWidth: "32px",
                          padding: 0,
                          flex: 1
                        }} size="medium" onClick={() => {
                          console.log('views.reportOptions')
                          console.log(views.reportOptions)
                          const selectedOption = (views.reportOptions).find((o) => {
                            return o.label === process.env.NEXT_PUBLIC_STRING_REPORT_T;
                          }).options.find((x) => {
                            return x.label === reportByTagsLanguage;
                          })
                          if (selectedOption && filters
                            // && Object.keys(filters).length > 0
                          ) {
                            console.log(reportByTagsLanguage)
                            console.log('filters')
                            console.log(filters)
                            let queryTags = "&tag_list="
                            filters.forEach((t, i) => {
                              queryTags = queryTags + (i > 0 ? "," : "") + t;
                            });
                            triggerAction(selectedOption.onClick, timeshift, queryTags)
                          } else {
                            toast.error("Select at least one tag to download the report.")
                          }
                        }}
                          className="muscope-cta"
                        // className="muscope-cta !text-white"
                        // className="muscope-cta inline-flex items-center gap-2 rounded-full font-medium"
                        // className="muscope-cta inline-flex items-center gap-4 rounded-full px-5 py-2.5 text-base font-medium"
                        >
                          <span className="sr-only">Download</span>
                          <svg fill="currentColor" className="inline h-4 w-4" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FileDownloadSharpIcon">
                            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
                          </svg>
                        </MDButton>
                        {
                          // report by tags download cta e
                        }
                      </div>
                    </MDBox>
                  </Card>
                </div>
                <div className="cysr-tags-setup-back" onClick={() => {
                  setShowSelectTags(false)
                }}></div>
              </>
            }
          </div>

        </div>

        <MDSnackbar
          color={msgTheme}
          icon={msgIcon}
          title={msgTitle}
          content={msgContent}
          dateTime={msgDateTime}
          open={show}
          close={defaultToggleSnackbar}
        />
      </>
    </DashboardLayout >
  )
}

export default Reports;

/* eslint-disable */
// prettier-ignore
