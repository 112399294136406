/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Authentication layout components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import RenderMarkdown from "layouts/cysr/components/RenderMarkdown";

function Basic() {
    const pageTitle = "Terms and Conditions";
    document.title = `${pageTitle} - ${document.title.split(" - ")[1] ? document.title.split(" - ")[1] : document.title}`;
  return (
    <PageLayout image={bgImage}>
      <Card style={{width: "800px", maxWidth: "80vw", margin: "32px auto"}}>
        <MDBox pt={2} pb={3} px={3}>
            <MDTypography variant="h4" fontWeight="medium" mb={2}>
                Terms and Conditions
            </MDTypography>
            <MDBox color="text" style={{display: "flex", flexDirection: "column", fontSize: "14px", gap: "12px"}}>
                <p>The following is a statement of compliance with the General Data Protection Regulation (GDPR) for the processing of personal data of CYSR platform users. Our service, CYSR, is managed by Muscope Cybersecurity Srl, which is committed to ensuring that all personal data of users are processed in compliance with GDPR.</p>
                <p>The data collected by the CYSR platform are managed by Muscope Cybersecurity Srl and stored in Europe. Muscope Cybersecurity Srl takes appropriate measures to ensure the security and confidentiality of the personal data collected and processed by the platform.</p>
                <p>The user of the CYSR platform and of Muscope Cybersecurity Srl services declares that they will only use the platform for their own assets and those for which they have the right, and that they will not use the service for illegal purposes or to violate the rights of others.</p>
                <p>The user agrees not to forward the data obtained from the platform to individuals who are not entitled to it and agrees not to publicly disclose the data obtained from the platform.</p>
                <p>The user takes full responsibility for their use of the data obtained from the platform. Muscope Cybersecurity Srl assumes no responsibility for the use of data outside of the platform.</p>
                <p>The user is reminded that the service may be interrupted or deactivated at any time and without notice by the platform operators. The Muscope Cybersecurity Srl is not responsible for any losses or damages resulting from interruptions or deactivations of the service.</p>
                <p>Finally, the user is invited to read the terms of service carefully and to accept them before using the platform. Use of the platform implies acceptance of these terms of service and the full understanding and acceptance of the user's responsibilities.</p>
            </MDBox>
        </MDBox>
      </Card>
    </PageLayout>
  );
}

export default Basic;
/* eslint-disable */