/* eslint-disable */
// prettier-ignore
// 'use client';
import { DonutChart, List, ListItem } from '@tremor/react';

const colors = [
    { name: null, class: 'bg-gray-500' },
    { name: "UNANSWERED", class: 'bg-gray-500' },

    { name: "L0 Not Performed", class: 'bg-rose-500' },
    { name: "Not Performed", class: 'bg-rose-500' },

    { name: "L1 Performed Informally", class: 'bg-orange-500' },
    { name: "Performed Informally", class: 'bg-orange-500' },

    { name: "L2 Planned & Tracked", class: 'bg-violet-500' },
    { name: "Planned & Tracked", class: 'bg-violet-500' },

    { name: "Well Defined", class: 'bg-sky-500' },
    { name: "L3 Well Defined", class: 'bg-sky-500' },

    { name: "Quantitatively Controlled", class: 'bg-blue-500' },
    { name: "L4 Quantitatively Controlled", class: 'bg-blue-500' },

    { name: "Continuously Improving", class: 'bg-teal-500' },
    { name: "L5 Continuously Improving", class: 'bg-teal-500' },
];

const colorsStatus = [
    { name: null, class: 'bg-gray-500' },
    { name: "UNANSWERED", class: 'bg-gray-500' },
    // { name: "UNANSWERED", class: 'bg-violet-500' },

    { name: "DRAFT", class: 'bg-orange-500' },

    { name: "ANSWERED", class: 'bg-emerald-500' }
];

const numberFormatter = (number) => {
    return number.toString();
};

export function CtrlPieQuestionnaire({ controls, controlsAnswers }) {
    
    let data = [];
    let dataStatus = [];
    const amountTotal = Object.keys(controls).length;

    let allControlsAnswers = controlsAnswers;
    // add empty answers to allControlsAnswers
    Object.keys(controls).forEach(x => {
        if(allControlsAnswers[x]){
            // ok
        } else {
            allControlsAnswers[x] = {
                answer: "UNANSWERED",
                answer_status: "UNANSWERED",
                answer_id: null,
            }
        }
    });
    
    Object.entries(allControlsAnswers)
        // sort by name
        .sort((a, b) => {
            const aN = a[1]?.answer || '';
            const bN = b[1]?.answer || '';
            return colors.findIndex(x => x.name?.replaceAll(" ", "") === aN.replaceAll(" ", "")) 
                 - colors.findIndex(x => x.name?.replaceAll(" ", "") === bN.replaceAll(" ", ""));
        })
        .forEach(([key, value]) => {
            const answer = value.answer ? value.answer.replaceAll(" ", "").toUpperCase() : "UNANSWERED";
            const answerStatus = value.answer_status ? value.answer_status.replaceAll(" ", "").toUpperCase() : "UNANSWERED";
    
            // Handle main data
            let alreadyIn = data.find(y => (y.name ? y.name.replaceAll(" ", "").toUpperCase() : "UNANSWERED") === answer);
            if (!alreadyIn) {
                alreadyIn = {
                    name: value.answer ?? "UNANSWERED",
                    color: colors.find(z => z.name?.replaceAll(" ", "") === value.answer?.replaceAll(" ", ""))?.class,
                    amount: 0,
                    share: ''
                };
                data.push(alreadyIn);
            }
            alreadyIn.amount++;
            alreadyIn.share = ((alreadyIn.amount / amountTotal) * 100).toFixed(0) + "%";
    
            // Handle status data
            let alreadyInStatus = dataStatus.find(y => (y.name ? y.name.replaceAll(" ", "").toUpperCase() : "UNANSWERED") === answerStatus);
            if (!alreadyInStatus) {
                alreadyInStatus = {
                    name: value.answer_status ?? "UNANSWERED",
                    color: colorsStatus.find(z => z.name?.replaceAll(" ", "") === value.answer_status?.replaceAll(" ", ""))?.class,
                    amount: 0,
                    share: ''
                };
                dataStatus.push(alreadyInStatus);
            }
            alreadyInStatus.amount++;
            alreadyInStatus.share = ((alreadyInStatus.amount / amountTotal) * 100).toFixed(0) + "%";
        });
    return (
        <div className='w-full flex flex-col md:flex-row justify-end gap-6 mb-4'>
            <div className='w-full flex gap-6 items-start'>
                {
                    // answer_status
                }
                <div className="w-full">
                    <p className="text-xs mt-8 flex items-center justify-between text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                        <span>Status</span>
                        <span>Count</span>
                    </p>
                    <List className="mt-2">
                        {dataStatus.map((item) => (
                            <ListItem key={item.name} className="space-x-6">
                                <div className="flex items-center space-x-2.5 truncate">
                                    <span
                                        className={
                                            (item.color ? item.color : "bg-teal-500") + ' h-2.5 w-2.5 shrink-0 rounded-sm'
                                        }
                                        aria-hidden={true}
                                    />
                                    <span className="truncate dark:text-dark-tremor-content-emphasis">
                                        {item.name}
                                    </span>
                                </div>
                                <div className="flex items-center gap-x-6">
                                    <span className="font-medium tabular-nums text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                        {numberFormatter(item.amount)}
                                    </span>
                                    <span className="rounded-tremor-small bg-tremor-background-subtle px-1.5 py-0.5 text-tremor-label font-medium tabular-nums text-tremor-content-emphasis dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis">
                                        {item.share}
                                    </span>
                                </div>
                            </ListItem>
                        ))}
                    </List>
                </div>
                <div className="w-full sm:max-w-lg">
                    <DonutChart
                        data={dataStatus}
                        category="amount"
                        index="name"
                        valueFormatter={numberFormatter}
                        showTooltip={false}
                        colors={dataStatus.map((z)=>z.color?.replace("bg-","").replace("-500", ""))}
                    />
                    <h3 className="text-center mt-4 text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                        Controls by status
                    </h3>
                </div>
            </div>
            <div className='w-full flex gap-6 items-start'>
                <div className="w-full sm:max-w-lg">
                    <DonutChart
                        data={data}
                        category="amount"
                        index="name"
                        valueFormatter={numberFormatter}
                        showTooltip={false}
                        // colors={['cyan', 'blue', 'indigo', 'violet', 'fuchsia']}
                        colors={data.map((z)=>z.color?.replace("bg-","").replace("-500", ""))}
                    />
                    <h3 className="text-center mt-4 text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                        Controls by maturity
                    </h3>
                </div>
                <div className="w-full">
                    <p className="text-xs mt-8 flex items-center justify-between text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                        <span>Level</span>
                        <span>Count</span>
                    </p>
                    <List className="mt-2">
                        {data.map((item) => (
                            <ListItem key={item.name} className="space-x-6">
                                <div className="flex items-center space-x-2.5 truncate">
                                    <span
                                        className={
                                            (item.color ? item.color : "bg-teal-500") + ' h-2.5 w-2.5 shrink-0 rounded-sm'
                                        }
                                        aria-hidden={true}
                                    />
                                    <span className="truncate dark:text-dark-tremor-content-emphasis">
                                        {item.name}
                                    </span>
                                </div>
                                <div className="flex items-center gap-x-6">
                                    <span className="font-medium tabular-nums text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                        {numberFormatter(item.amount)}
                                    </span>
                                    <span className="rounded-tremor-small bg-tremor-background-subtle px-1.5 py-0.5 text-tremor-label font-medium tabular-nums text-tremor-content-emphasis dark:bg-dark-tremor-background-subtle dark:text-dark-tremor-content-emphasis">
                                        {item.share}
                                    </span>
                                </div>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </div>
        </div>
    );
}